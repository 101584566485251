import styled from 'styled-components';

export const DefaultButton = styled.button`
    width:100%;
    background-color:  ${({red}) => (red && 'red') || '#2D3A42'};
    color:#ffffff;
    border-radius:4px;
    padding:12px 24px 12px 24px;
    font-size:15px;
    height:48px;
    box-shadow:none;
    border:none;
    cursor:pointer;

    &.disable{
        opacity:0.5;
        cursor: no-drop;
    } 

    &.w-auto{
        width:auto;
    }

    &.absolute{
        position:absolute;
    }

    &.bottom-20{
        bottom:20px;
    }

    &.left-20{
        left:20px;
    }

    &.right-20{
        right:20px;
    }

    &.earn-btn{
        background-color:var(--bg-vip-btn);
        color:var(--white-color);
        opacity:0.80;
        font-size:12px;
    }
    &.vip_premium_btn {
        background-color:var(--bg-perks);
        color:var(--white-color);
        opacity:0.50;
        font-size:12px;
        padding:0 12px;
        height:48px;
    }

    &.point_history{
        height:38px;
        background: transparent;
        border:1px solid var(--border-transparent-color);
        border-radius:30px;
        padding:10px 18px;
    }

    &.EventPoints{
        height:34px;
        background: transparent;
        font-size:12px;
        background:var(--bg-input-field);
        border:1px solid var(--border-updates-color);
        border-radius:30px;
        padding:8px 10px;
        letter-spacing:1px;
    }

    &.vault_scroll_Btn{
        width:auto;
        font-size:12px;
        padding:15px 12px 15px 12px;
        background:var(--bg-perks);
        color:var(--gray-color);
    }

    &.skipin {
        background:var(--bg-transparent-color);
        border:1px solid var(--border-transparent-color);
    }
    &.custom_bottom {
        padding: 12px 15px 12px 15px;
    }
    &.cancel_wd {
        width:60%;
    }
    &.premium_btn {
        padding:8px 17px;
        width:auto;
        color:var(--white-color);
        border:1px solid var(--border-transparent-color);
        background-color:var(--transparent);
        border-radius:999px;
        font-size:14px;
        font-weight:400;
        font-family:var(--font-Regular);
        height:auto;
        margin:0;
    }
    &.disable_dark[disabled] {
        opacity: 0.50;
        color:var(--disable-color);
        cursor: auto;
    }
    &.need_dash {
        color:var(--point-gray-color);
    }
`
