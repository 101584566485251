import React from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import NewsLargeImg from '../assets/images/news_large.svg';
import Header from "../components/Header";
import { H4, Paragraph } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import { useNavigate } from "react-router-dom";

function Articles() {
    const navigate = useNavigate();
    return (
        <MainContainer>
            <Background />
            <SubContainer className="pt-80">

                <Header backIcon={true} settingIcon={true} />

                <Main className="padding-25 relative">
                    <Section className="p-15 news-auto">
                        <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
                        <H4 white="true" className="fontSemiBold mt-0 mb-10">Bon Jovi announces a new artifact collection</H4>
                        <Paragraph white="true" className="opacity-60 lineheight-18 mt-0">Located at Hauz Khas in Delhi, the Bar/Pub Imperfecto is easily  accessible to all your guests. In business since 2010 Imperfecto is one  of the most popular event venues in city. Imperfecto has been a prime  location to celebrate Birthday Party, Bachelor Party, Social Gathering  and many other events. Imperfecto can accommodate between 10 - 50 guests  at a time. Imperfecto provides numerous facilities like Power Backup,  Air Condition, DJ and many more which ensures that your event is  successful.</Paragraph>
                    </Section>
                    <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-560 m-auto">
                        <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom" onClick={() => navigate(-1)}>Go Back</DefaultButton>
                    </Section>
                </Main>
            </SubContainer>
        </MainContainer>
    );
}

export default Articles;