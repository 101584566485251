import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, } from "../components/Styles";
import { Background } from "../components/Background";
import InnerHeader from "../components/InnerHeader";
import CalendarBlankIcon from '../assets/images/calendarblank.svg';
import { DefaultButton } from "../components/DefaultButton";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import userApi from "../utils/userApi";

function BirthDate() {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = React.useState("")
    const handleDateChange = (date) => {
        if (date) {
            let savingDate = new Date(date);
            savingDate.setHours(23, 59, 0, 0);
            setSelectedDate(savingDate);
        } else {
            setSelectedDate(null);
        }
    }
    React.useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter" && selectedDate) {
                navigate("/customise-vault");
            } else if (e.key === "Escape") {
                navigate(-1);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [selectedDate, navigate]);

    const submit =()=>{
        const newDate = new Date(selectedDate);
        const date = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
        // console.log(newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate());
        const params = {
          birthdate: date,
        }
        userApi.setBirthdateApi(params).then((res) => {
            // console.log(res);
            if (res.status) {
                if (res.message === "Birthdate set") {
                    navigate("/customise-vault")
                }
            }
        });  
    }
    return (
        <MainContainer>
            <Background />
            <SubContainer>
                <Main className="padding-25 relative">

                    <InnerHeader PageTitle={"Birthdate"} helpIcon={true} helpLink={"/public-profile"} />

                    <Section>
                        <Section className="mb-20">
                            <Section className="relative births">
                                {/* <Image src={CalendarBlankIcon} className="absolute w-auto top-12 right-15 zindex" /> */}
                                {/* <Input className="inputBg mt-10 fontRegular birth_date" placeholder="MM/DD/YYYY" /> */}
                                <DatePicker
                                    wrapperClassName="w-full pl-15"
                                    autoComplete="off"
                                    maxDate={new Date()}
                                    selected={selectedDate}
                                    showMonthDropdown={true}
                                    popperClassName="bg-getstartgray text-lightwhite"
                                    showYearDropdown={true}
                                    icon={
                                        <img
                                            src={CalendarBlankIcon}
                                            className="absolute w-auto top-12 right-15 zindex"
                                            alt="Custom Icon"
                                        />
                                    }
                                    onChange={(e) => {
                                        handleDateChange(e)
                                    }}
                                    placeholderText={'MM/DD/YYYY'}
                                    toggleCalendarOnIconClick
                                    showIcon
                                />
                            </Section>
                        </Section>
                    </Section>
                    <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto">
                        <DefaultButton className={`${!(selectedDate) ? "disable" : ""} mb-10 text-small-base fontSemiBold custom_bottom`} disabled={!(selectedDate)} onClick={submit}>Confirm</DefaultButton>
                        <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom" onClick={() => navigate(-1)}>Go Back</DefaultButton>
                    </Section>
                </Main>
            </SubContainer>
        </MainContainer>
    );
}

export default BirthDate;