const DAUserModel = {
    parseDefault(data) {
        // console.log(data);
        const response = {
            status: false,
            message: {},
        }
        if (data.status) {
            response.status = true
            response.message = data.data
        } else {
            response.message = data.data
        }
        return response
    },
    parseErrorDefault(data) {
        // console.log(data);
        const response = {
            status: false,
            error: data.data.error ? data.data.error : "Something went wrong"
        }       
        return response
    },
}

export default DAUserModel
