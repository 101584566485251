import React from "react";
import {  MainContainer, SubContainer, Main, Image } from "../components/Styles";
import styled from "styled-components";
import { Background } from "../components/Background";
import { H5, Paragraph } from "../components/Text";
import InnerHeader from "../components/InnerHeader";
import HistoryIcons from "../assets/images/points_history.svg"


const PointHistoryContainer = styled.div`
    
ul{
    margin-bottom:0;

    li{
        border
    }
}

`;

function PointsHistory() {  
    // const goToCustomiseVault = () => {
    //     navigate("/customise-vault")
    // }
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25">

           <InnerHeader PageTitle={"Points History"} helpIcon={false} helpLink={"/customise-vault"} />

            <Main className="mb-20 flex content-between">
                <Paragraph gray="true">Type</Paragraph>
                 <Paragraph white="true">Points Won</Paragraph> 
            </Main>

            <PointHistoryContainer>
             <ul className="p-0 mb-0 mt-0">
              <li className="flex mb-10 content-between items-center borderBottom-1">
                <Main className="flex">
                    <Image src={HistoryIcons} className="mr-15" />
                    <H5 gray="true" className="pr-15">Checked in to ‘Dash Bash’</H5>
                </Main>
                <H5 gray="true">100</H5>
              </li>

              <li className="flex mb-10 content-between items-center borderBottom-1">
                <Main className="flex">
                <Image src={HistoryIcons} className="mr-15" />
                <H5 gray="true" className="pr-15">Checked in to ‘Dash Bash’</H5>
                </Main>
                <H5 gray="true">100</H5>
              </li>

              <li className="flex mb-10 content-between items-center borderBottom-1">
                <Main className="flex">
                <Image src={HistoryIcons} className="mr-15" />
                <H5 gray="true" className="pr-15">Checked in to ‘Dash Bash’</H5>
                </Main>
                <H5 gray="true">100</H5>
              </li>

              <li className="flex mb-10 content-between items-center borderBottom-1">
                <Main className="flex">
                <Image src={HistoryIcons} className="mr-15" />
                <H5 gray="true" className="pr-15">Checked in to ‘Dash Bash’</H5>
                </Main>
                <H5 gray="true">100</H5>
              </li>

              <li className="flex mb-10 content-between items-center borderBottom-1">
                <Main className="flex">
                <Image src={HistoryIcons} className="mr-15" />
                <H5 gray="true" className="pr-15">Checked in to ‘Dash Bash’</H5>
                </Main>
                <H5 gray="true">100</H5>
              </li>

             </ul>
            </PointHistoryContainer>
           </Main>
          </SubContainer>
        </MainContainer>
    );
    }

export default PointsHistory;