/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Background } from "../components/Background";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Paragraph, Span, H1 } from "../components/Text";
import Document from "../assets/images/document.svg";
import Shield from "../assets/images/shield.svg";
import ButtonRight from "../assets/images/button-arrow-right.svg"
import arrowRight from "../assets/images/arrow-right.svg";
import GrayStar from "../assets/images/star-gray.svg"
import BasicTier from "../assets/images/basic-tier.svg"
import PlusCircle from "../assets/images/plus-circle.svg"
import PremiumCircle from "../assets/images/premium-circle.svg"
import UltraCircle from "../assets/images/ultra-circle.svg"
import AlistCircle from "../assets/images/alist-circle.svg"
import { DefaultButton } from "../components/DefaultButton"
import GaugeComponent from 'react-gauge-component'
import { useSearchParams } from 'react-router-dom';

const VaultContainer = styled.div`
    .tier-sub{
    background:var(--bg-vault-sub);
    }

    .alist-sub{
        background:var(--bg-vault-alist);
    }

    .collectible{
        width:49%;

        .collectible-sub{
            background:var(--bg-perks);
            border-radius:4px;
            padding:15px;
            margin:8px;
        }
    }
`;


function Vault() {
    const [value, setvalue] = React.useState(0)
    const [min, setMin] = React.useState(0)
    const [max, setMax] = React.useState(200)
    const { state } = useLocation();
    const [searchParams] = useSearchParams();
    const [percent, setPercent] = useState(50); 
    const [subArcs, setSubArcs] = React.useState([])
    const [completedCount, setCompletedCount] = React.useState(1);
    const [nesxtTier, setNextTier] = React.useState("PLUS")
    const [type, setType] = React.useState("basic")
    const [tier, setTier] = React.useState("VIP")
    const navigate = useNavigate();   
    const [completedStatus, setCompletedStatus] = React.useState({
        email: true,
        public_profile: false,
        birthdate: false,
        location: false,
        favorite_genres: false,
        favorite_artists: false
      });
      const totalCount = Object.keys(completedStatus).length
      const initStatus = () => {
        if(state && state.completeStep !== "") {
          setCompletedStatus({
            ...completedStatus,
            [state.completeStep]: true
          })     
          setCompletedCount(2)
          // setTimeout(() => {
          //   const completedObj = Object.keys(completedStatus).filter(i => completedStatus[i] === true)
          //   setCompletedCount(Object.keys(completedObj).length)
          // }, 200)   
        } 
      }
    

      useEffect(() => {   
        initStatus()     
      }, [])

      
    React.useEffect(() => { 
        const per = ((completedCount) / totalCount) * 100;
        setPercent(per);
        const type = searchParams.get('tier');
        setType(type ? type : "basic");
        if (type === "plus") {
            setTier("PLUS")
            setNextTier("PREMIUM")
            setMax(900)
            setvalue(650)
            setSubArcs([
                {
                    limit: 150,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 300,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 450,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 600,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 750,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 900,
                    color: '#fff',
                    showTick: true
                },
            ])
        } else if (type === "premium") {
            setTier("PREMIUM")
            setNextTier("ULTRA")
            setMax(5000)
            setvalue(3650)
            setSubArcs([
                {
                    limit: 800,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 1600,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 2500,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 3300,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 4100,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 5000,
                    color: '#fff',
                    showTick: true
                },
            ])
        } else if (type === "ultra") {
            setTier("ULTRA")
            setNextTier("ULTRA")
            setMax(5000)
            setvalue(3650)
            setSubArcs([
                {
                    limit: 800,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 1600,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 2500,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 3300,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 4100,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 5000,
                    color: '#fff',
                    showTick: true
                },
            ])
        } else if (type === "alist") {
            setvalue(6000)
            setTier("A-LIST")
        } else {
            setMax(200)
            setvalue(155)
            setSubArcs([
                {
                    limit: 35,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 70,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 100,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 135,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 170,
                    color: '#fff',
                    showTick: true
                },
                {
                    limit: 200,
                    color: '#fff',
                    showTick: true
                },
            ])

        }

    }, [completedCount])
    const pointsHistory = () => {
        navigate("/points-history")
    }
    return (

        <MainContainer>
            <VaultContainer>

                <Background />

                <Main className={`${type === "alist" ? "basic" : type}-tier`}>
                <SubContainer className="pt-80">
                        <Header vault={true} profileIcon={true} settingIcon={true} tier={tier} />

                        <Main className="pr-15 pl-15 pb-15">
                            <Main className="tier-sub p-15 pt-20 z-10 relative">


            <Main className="pt-15 pb-15" onClick={() => navigate("/customise-vault")}>
                <Background className="customize_first pl-15 pr-15 pt-13 pb-13 radius-4">
                    <Section className="displayflex content-between">
                        <Paragraph white="true" className="mt-0 opacity-50">Finished {completedCount}/{totalCount}</Paragraph>
                        <Paragraph white="true" className="mt-0 opacity-50">10 points earned</Paragraph>
                    </Section>
                    <Section className="displayblock pt-6">
                        <progress className="w-full h-2 displayblock" max="100" value={percent}></progress> 
                    </Section>
                </Background>
            </Main>
                                <Main className="flex content-between">
                                    <Main>
                                        <Paragraph className="flex items-center mt-0">
                                            <Image src={GrayStar} />
                                            <Span className="pl-10" textGray="true">Points Balance</Span>
                                        </Paragraph>
                                        <H1 white="true" className="mt-5">{value}</H1>
                                    </Main>

                                    <Main>
                                        <DefaultButton className="point_history flex items-center" onClick={pointsHistory}>
                                            <Span className="pr-5">Points History</Span>
                                            <Image src={ButtonRight} className="cursor-pointer pl-10 pr-0" />
                                        </DefaultButton>
                                    </Main>
                                </Main>

                                <Main className="flex nowrap overflow-x scroll-bar pb-10">
                                    <DefaultButton className="flex vault_scroll_Btn mr-10">
                                        <Image src={Document} className="pr-10" />
                                        Read-access to exclusive News
                                    </DefaultButton>

                                    <DefaultButton className="flex vault_scroll_Btn mr-10">
                                        <Image src={Document} className="pr-10" />
                                        Special event tickets
                                    </DefaultButton>

                                    <DefaultButton className="flex vault_scroll_Btn mr-10">
                                        <Image src={Document} className="pr-10" />
                                        Live streams of
                                    </DefaultButton>
                                </Main>
                                {type !== "alist" && (
                                    <>
                                    <Main className="relative GauageCompo mt-15 mb-15">
                                        <Main>
                                            <GaugeComponent                                            
                                                arc={{
                                                    subArcs,
                                                    width: 0.10,
                                                    cornerRadius: 5,
                                                    // color: '#ccc'
                                                }}
                                                value={value}
                                                minValue={min}
                                                maxValue={max}
                                                labels={{
                                                    valueLabel: {
                                                        formatTextValue: value => `
                                            `
                                                    },
                                                    tickLabels: {
                                                        type: 'outer',
                                                        valueConfig: {
                                                            formatTextValue: value => `${value}`,
                                                            fontSize: 10
                                                        },
                                                    }
                                                }}

                                            />
                                            </Main>

                                            <Main className="gauge-center-message">
                                                <Span gray="true" className="text-xs">Next tier</Span>
                                                <H1>VIP {nesxtTier}</H1>
                                                <Paragraph gray="true">You need 60 more points to upgrade. Keep going!</Paragraph>
                                            </Main>
                                    </Main>
                                    </>
                                )}

                            </Main>
                        </Main>

                        {type === "alist" && (
                            <Image src={AlistCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
                        )}
                        {type === "plus" && (
                            <Image src={PlusCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
                        )}
                        {type === "premium" && (
                            <Image src={PremiumCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
                        )}
                        {type === "VIP" && (
                            <Image src={BasicTier} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
                        )}
                        {type === "ultra" && (
                            <Image src={UltraCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
                        )}
                    </SubContainer>
                </Main>

                <SubContainer className="pb-100" >

                    <Main className="p-15 flex items-center content-between">
                        <Paragraph textGray="true">YOUR COLLECTIBLES</Paragraph>
                        <Image src={arrowRight} className="cursor-pointer" />
                    </Main>

                    <Main className="p-15 flex flex-wrap pt-0">

                        <Main className="collectible">
                            <Main className="collectible-sub">
                                <Image src={Shield} />
                                <Paragraph white="true">
                                    Attend the JBJ event in Nashville
                                </Paragraph>

                                <Paragraph className="flex items-center">
                                    <Image src={GrayStar} />
                                    <Span className="text-line pl-10" textGray="true">+150 Points</Span>
                                </Paragraph>
                            </Main>
                        </Main>

                        <Main className="collectible">
                            <Main className="collectible-sub">
                                <Image src={Shield} />
                                <Paragraph white="true">
                                    Buy an artifact
                                </Paragraph>

                                <Paragraph className="flex items-center">
                                    <Image src={GrayStar} />
                                    <Span className="text-line pl-10" textGray="true">+500 Points</Span>
                                </Paragraph>
                            </Main>
                        </Main>

                        <Main className="collectible">
                            <Main className="collectible-sub">
                                <Image src={Shield} />
                                <Paragraph white="true">
                                    Collect at least
                                    3 collectibles
                                </Paragraph>

                                <Paragraph className="flex items-center">
                                    <Image src={GrayStar} />
                                    <Span className="text-line pl-10" textGray="true">+10 Points</Span>
                                </Paragraph>
                            </Main>
                        </Main>



                    </Main>

                    <Footer />
                </SubContainer>
            </VaultContainer>
        </MainContainer>

    );
}

export default Vault;