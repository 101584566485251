import React from "react";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import LargeqrCodeIcon from '../assets/images/largeqrcode.svg';
import { H2, Paragraph } from "../components/Text";
import InnerHeader from "../components/InnerHeader";

function OrderQrcode() {
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25 relative">
           <InnerHeader PageTitle={"Order a Red Wine"} helpIcon={false} helpLink={"/public-profile"} />
            <Section className="text-center mt-20">
             <Background className="qr_code_bg pt-33 pb-33 max-244 m-auto displayblock radius-24"> 
              <Image className="m-auto displayblock" src={LargeqrCodeIcon} />
             </Background> 
             <H2 lightwhite="true" className="mtb-auto max-320 opacity-94">Scan your QR Code at events to get access</H2>
             <Paragraph lightwhite="true" className="opacity-70 qr_para">Don't worry, we don't share any of your private info with other members</Paragraph>
            </Section>
           </Main>
          </SubContainer>
        </MainContainer>  
    );
    }

export default OrderQrcode;