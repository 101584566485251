/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Background } from "../components/Background";

import Footer from "../components/Footer";
import Header from "../components/Header";
import { Paragraph, Span, H2 } from "../components/Text";
import Shield from "../assets/images/shield.svg";
import arrowRight from "../assets/images/arrow-right.svg";
import GrayStar from "../assets/images/star-gray.svg"
import { getUserId } from "../utils/authUtils";
import userApi from "../utils/userApi";
import { Buffer } from 'buffer';
import QR from '../assets/images/largeqrcode.svg';

const VaultContainer = styled.div`
    .tier-sub{
    background:var(--bg-vault-sub);
    }

    .alist-sub{
        background:var(--bg-vault-alist);
    }

    .collectible{
        width:49%;

        .collectible-sub{
            background:var(--bg-perks);
            border-radius:4px;
            padding:15px;
            margin:8px;
        }
    }
`;


function Home() {

    const navigate = useNavigate();
    const [image, setImage] = useState("")



    useEffect(() => {
        const userId = getUserId()
        if(!userId) {
            navigate("/login")
        }
        console.log(userId);
        const params = {
            userID: userId
        }
        userApi.generateQrCodeApi(params).then((res) => {
            if (res.status === true) {
                const img = res.message
                let base64ImageString = Buffer.from(img, 'binary').toString('base64')
                let srcValue = `data:image/png;base64,${base64ImageString}`
                setImage(srcValue);
            }
        });
    }, [image])
    return (

        <MainContainer>
            <VaultContainer>

                <Background />

                <Main className="basic-tier">
                    <SubContainer className="pt-80">
                        <Header vault={true} profileIcon={false} settingIcon={false}/>

                        <Main className="pr-15 pl-15 pb-15">
                            <Main className="tier-sub p-15 pt-20 z-10 relative">
                                <Section className="text-center mt-20">
                                    <Background className="qr_code_bg pt-33 pb-33 max-244 m-auto displayblock radius-24">
                                        {image !== ""
                                            ? <Image className="m-auto displayblock qrcodeImg" src={image} />
                                            : <Image className="m-auto displayblock qrcodeImg" src={QR} />
                                        }
                                    </Background>
                                    <H2 lightwhite="true" className="mtb-auto max-320 opacity-94">Scan your QR Code to get collectibles</H2>
                                    <Paragraph lightwhite="true" className="opacity-70 qr_para">Don't worry, we don't share any of your private info with other members</Paragraph>
                                </Section>
                            </Main>
                        </Main>
                    </SubContainer>
                </Main>

                <SubContainer className="pb-100" >

                    <Main className="p-15 flex items-center content-between">
                        <Paragraph textGray="true">YOUR COLLECTIBLES</Paragraph>
                        <Image src={arrowRight} className="cursor-pointer" />
                    </Main>

                    <Main className="p-15 flex flex-wrap pt-0">

                        <Main className="collectible">
                            <Main className="collectible-sub">
                                <Image src={Shield} />
                                <Paragraph white="true">
                                    Attend the JBJ event in Nashville
                                </Paragraph>

                                <Paragraph className="flex items-center">
                                    <Image src={GrayStar} />
                                    <Span className="text-line pl-10" textGray="true">+150 Points</Span>
                                </Paragraph>
                            </Main>
                        </Main>

                        <Main className="collectible">
                            <Main className="collectible-sub">
                                <Image src={Shield} />
                                <Paragraph white="true">
                                    Buy an artifact
                                </Paragraph>

                                <Paragraph className="flex items-center">
                                    <Image src={GrayStar} />
                                    <Span className="text-line pl-10" textGray="true">+500 Points</Span>
                                </Paragraph>
                            </Main>
                        </Main>

                        <Main className="collectible">
                            <Main className="collectible-sub">
                                <Image src={Shield} />
                                <Paragraph white="true">
                                    Collect at least
                                    3 collectibles
                                </Paragraph>

                                <Paragraph className="flex items-center">
                                    <Image src={GrayStar} />
                                    <Span className="text-line pl-10" textGray="true">+10 Points</Span>
                                </Paragraph>
                            </Main>
                        </Main>



                    </Main>

                    <Footer />
                </SubContainer>
            </VaultContainer>
        </MainContainer>

    );
}

export default Home;