import React from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsLargeImg from '../assets/images/news_large.svg';
import {  H4, Paragraph } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton"; 

function EventSigned() {
    var settings = {
        dots: false,
        centerMode: true,
        centerPadding: '25px',
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <MainContainer>
            <Background />
            <SubContainer>
                <Main className="padding-25 relative text-center">
                    <Section>
                        <Slider className="sets" {...settings}>
                            <Section className="displayblock overflowhidden outlinenone boxshadownone">
                                <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
                            </Section>
                            <Section className="displayblock overflowhidden outlinenone boxshadownone">
                                <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
                            </Section>
                            <Section className="displayblock overflowhidden outlinenone boxshadownone">
                                <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
                            </Section>
                        </Slider>
                    </Section>
                    <Section className="p-15 news-auto">
                        <H4 white="true" className="fontSemiBold mt-0 mb-10">You signed into 'Dash Bash - New York'</H4>
                        <Paragraph white="true" className="opacity-60 lineheight-18 mt-0">You've successfully been awarded 50 points.</Paragraph>
                    </Section>
                    <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-560 m-auto">
                        <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom disable_dark">Let's Go</DefaultButton>
                    </Section>
                </Main>
            </SubContainer>
        </MainContainer>
    );
}

export default EventSigned;