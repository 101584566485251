import React from "react";
import { useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import ScanIcon from '../assets/images/scan.svg';
import ArrowDownIcon from '../assets/images/arrow-down.svg';
import ArrowRightIcon from '../assets/images/arrow-right.svg';
import { H2, Paragraph, LinkText } from "../components/Text";
import InnerHeader from "../components/InnerHeader";

function Points() {

    const [openSections, setOpenSections] = React.useState(0);

    const toggleSection = (index, title) => {        

        if (openSections === index) {
            setOpenSections(null);
        } else {
            setOpenSections(index);
        }
    };
    const sectionsData = [
        {
            title: "Scan into events",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.",
        },
        {
            title: "Customize your vault",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.",
        },
        {
            title: "Complete challenges",
            content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.",
        }        
    ];

    return (
        <MainContainer>
            <Background />
            <SubContainer>
                <Main className="padding-25">

                    <InnerHeader PageTitle={"Points"} helpIcon={false} helpLink={"/welcome"} />

                    <Section className="mb-20">
                        <H2 white="true">How to earn more points</H2>
                        <Paragraph className="subpoints opacity-30">Here are ways you can earn more points</Paragraph>
                    </Section>
                    <Section>
                        <ul className="p-0 mb-0 mt-0">
                            {sectionsData.map((item, index) => (
                                <li key={index} className="block mb-10">
                                    <Background className="bg-points radius-4">
                                        <LinkText
                                            white="true"
                                            className="padding-16 block whitepoint relative"
                                            onClick={() => toggleSection(index, item.title)}
                                        >
                                            <Image className="vertical-middle mr-5" src={ScanIcon} />
                                            {item.title}
                                            <Image className="absolute right-20 top-15" src={openSections === index ? ArrowDownIcon : ArrowRightIcon} />
                                        </LinkText>
                                        {openSections === index && (
                                            <Section className="padding-16 pt-0 w-auto">
                                                <Paragraph className="mt-0 point_txt">
                                                {item.content}
                                                </Paragraph>
                                            </Section>
                                        )}
                                    </Background>
                                </li>
                            ))}
                            {/* <li className="block mb-10">
               <Background className="bg-points radius-4">
                <LinkText white="true" className="padding-16 block whitepoint relative"><Image className="vertical-middle mr-5" src={ScanIcon} />Scan into Events<Image className="absolute right-20 top-15" src={ArrowDownIcon} /></LinkText>
                <Section className="padding-16 pt-0 w-auto">
                <Paragraph className="mt-0 point_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.</Paragraph>
                </Section>
               </Background> 
              </li>
              <li className="block mb-10">
               <Background className="bg-points radius-4">
                <LinkText white="true" className="padding-16 block whitepoint relative" onClick={() => goToCustomiseVault()} ><Image className="vertical-middle mr-5" src={ScanIcon} />Customize your vault<Image className="absolute right-20 top-15" src={ArrowDownIcon} /></LinkText>
                <Section className="padding-16 pt-0 w-auto displaynone">
                <Paragraph className="mt-0 point_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.</Paragraph>
                </Section>
               </Background> 
              </li>
              <li className="block mb-10">
               <Background className="bg-points radius-4">
                <LinkText white="true" className="padding-16 block whitepoint relative"><Image className="vertical-middle mr-5" src={ScanIcon} />Scan into Events<Image className="absolute right-20 top-15" src={ArrowDownIcon} /></LinkText>
                <Section className="padding-16 pt-0 w-auto displaynone">
                <Paragraph className="mt-0 point_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.</Paragraph>
                </Section>
               </Background> 
              </li>
              <li className="block mb-10">
               <Background className="bg-points radius-4"> 
                <LinkText white="true" className="padding-16 block whitepoint relative"><Image className="vertical-middle mr-5" src={ScanIcon} />Scan into Events<Image className="absolute right-20 top-15" src={ArrowDownIcon} /></LinkText>
                <Section className="padding-16 pt-0 w-auto displaynone">
                <Paragraph className="mt-0 point_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.</Paragraph>
                </Section>
               </Background> 
              </li>
              <li className="block mb-10">
               <Background className="bg-points radius-4"> 
                <LinkText white="true" className="padding-16 block whitepoint relative"><Image className="vertical-middle mr-5" src={ScanIcon} />Scan into Events<Image className="absolute right-20 top-15" src={ArrowDownIcon} /></LinkText>
                <Section className="padding-16 pt-0 w-auto displaynone">
                <Paragraph className="mt-0 point_txt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam tincidunt rutrum tempus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec eget accumsan dolor, id consequat diam.</Paragraph>
                </Section>
               </Background> 
              </li> */}
                        </ul>
                    </Section>
                </Main>
            </SubContainer>
        </MainContainer>
    );
}

export default Points;