import React from "react";
import { MainContainer, SubContainer, Image } from "../components/Styles";
import { Background } from "../components/Background";
import SiteLogo from "../assets/images/MainLogo.svg"
import WelcomeOne from "./welcome-one";

function Welcome() {
    const [showWelcome, setShowWelcome] = React.useState(false)
    setTimeout(() => {
        setShowWelcome(true)
    }, 3000);
    const click = () => {
        setShowWelcome(true)
    }
    return (
        <MainContainer>
            <Background />
            <SubContainer >
                {!showWelcome ? (
                    <Image src={SiteLogo} alt="site logo" className="absolute m-auto left-0 right-0 top-0 bottom-0 zoom-in-out-box" onClick={click}/>) : (
                    <WelcomeOne />
                )
                }

            </SubContainer>
        </MainContainer>
    );
}

export default Welcome;
