/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, Image, Input, Textarea } from "../components/Styles";
import { Background } from "../components/Background";
import InnerHeader from "../components/InnerHeader";
import UserCircleIcon from '../assets/images/usercircle.svg';
import EditPencilIcon from '../assets/images/edit_pencil.svg';
import CalendarBlankIcon from '../assets/images/calendarblank.svg';
import CloseIcon from '../assets/images/close.svg';
import { Label, Ul, Li, LinkText } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import DatePicker from "react-datepicker"
import userApi from "../utils/userApi";

function AccountDetails() {
  const navigate = useNavigate();
  const [isTextareaFocused, setIsTextareaFocused] = React.useState(false);
  const [formData, setFormData] = React.useState({
    publicname: "",
    bio: ""
  });
  const [selectedDate, setSelectedDate] = React.useState("");
  const genres = [
    { id: 1, name: 'Rock', icon: CloseIcon },
    { id: 2, name: 'Pop', icon: CloseIcon },
    { id: 3, name: 'Hip hop', icon: CloseIcon },
    { id: 4, name: 'Heavy metal', icon: CloseIcon },
    { id: 5, name: 'Jazz', icon: CloseIcon },
    { id: 6, name: 'Country', icon: CloseIcon },
    { id: 7, name: 'Classical', icon: CloseIcon },
    { id: 8, name: 'Blues', icon: CloseIcon },
    { id: 9, name: 'Electronic', icon: CloseIcon },
    { id: 10, name: 'Folk', icon: CloseIcon },
    { id: 11, name: 'Hard rock', icon: CloseIcon },
    { id: 12, name: 'Indie', icon: CloseIcon },
    { id: 13, name: 'Instrumental', icon: CloseIcon },
    { id: 14, name: 'K-pop', icon: CloseIcon },
    { id: 15, name: 'Reggae', icon: CloseIcon },
    { id: 16, name: 'Rapping', icon: CloseIcon },
    { id: 17, name: 'Techno', icon: CloseIcon },
    { id: 18, name: 'Disco', icon: CloseIcon }
  ]
  const artists = [
    { id: 1, name: 'Taylor Swift', icon: CloseIcon },
    { id: 2, name: 'Ariana Grande', icon: CloseIcon },
    { id: 3, name: 'Beyonce', icon: CloseIcon },
    { id: 4, name: 'The Weeknd', icon: CloseIcon },
    { id: 5, name: 'Drake', icon: CloseIcon },
    { id: 6, name: 'Ed Sheeran', icon: CloseIcon },
    { id: 7, name: 'Eminem', icon: CloseIcon },
    { id: 8, name: 'Shakira', icon: CloseIcon },
    { id: 9, name: 'Lana Del Rey', icon: CloseIcon },
    { id: 10, name: 'NewJeans', icon: CloseIcon },
    { id: 11, name: 'BTS', icon: CloseIcon },
    { id: 12, name: 'Lady Gaga', icon: CloseIcon },
    { id: 13, name: 'Madonna', icon: CloseIcon },
    { id: 14, name: 'Miley Cyrus', icon: CloseIcon },
    { id: 15, name: 'Harry Styles', icon: CloseIcon },
    { id: 16, name: 'Jennifer Lopez', icon: CloseIcon },
    { id: 17, name: 'Imagine Dragons', icon: CloseIcon },
    { id: 18, name: 'Stray Kids', icon: CloseIcon },
    { id: 19, name: 'Bruno Mars', icon: CloseIcon },
    { id: 20, name: 'Karol G', icon: CloseIcon },
    { id: 21, name: 'BLACKPINK', icon: CloseIcon },
    { id: 22, name: 'Kelly Clarkson', icon: CloseIcon },
    { id: 23, name: 'Glass Animals', icon: CloseIcon },
    { id: 24, name: 'Kanye West', icon: CloseIcon },
    { id: 25, name: 'XG', icon: CloseIcon },
    { id: 26, name: 'NCT', icon: CloseIcon },
    { id: 27, name: 'City Girls', icon: CloseIcon },
    { id: 28, name: 'Charli XCX', icon: CloseIcon },
    { id: 29, name: 'Coldplay', icon: CloseIcon },
    { id: 30, name: 'Travis Scott', icon: CloseIcon },
    { id: 31, name: 'Green Day', icon: CloseIcon },
    { id: 32, name: 'The Last Dinner Party', icon: CloseIcon },
    { id: 33, name: 'The Rolling Stones', icon: CloseIcon },
    { id: 34, name: 'Foo Fighters', icon: CloseIcon },
    { id: 35, name: 'Red Hot Chili Peppers', icon: CloseIcon },
    { id: 36, name: 'Brennan Heart', icon: CloseIcon },
    { id: 37, name: 'Les Attitudes Spectrales', icon: CloseIcon },
    { id: 38, name: 'Maustetytöt', icon: CloseIcon },
    { id: 39, name: 'Zoster', icon: CloseIcon },
    { id: 40, name: 'Korn', icon: CloseIcon },
    { id: 41, name: 'Selena Gomez', icon: CloseIcon },
    { id: 42, name: 'Katy Perry', icon: CloseIcon },
    { id: 43, name: 'Spiritbox', icon: CloseIcon },
    { id: 44, name: 'Deap Vally', icon: CloseIcon },
    { id: 45, name: 'Sacred Reich', icon: CloseIcon },
    { id: 46, name: 'Chevelle', icon: CloseIcon },
    { id: 47, name: 'Toadies', icon: CloseIcon },
    { id: 48, name: 'Sugababes', icon: CloseIcon },
    { id: 49, name: 'Nasty Savage', icon: CloseIcon },
    { id: 50, name: 'Testament', icon: CloseIcon },
    { id: 51, name: 'Buffalo Tom', icon: CloseIcon }
  ];
  const [favoriteGenres, setFavoriteGenres] = React.useState(genres);
  const [favoriteArtists, setFavoriteArtists] = React.useState(artists);
  const location = useLocation();
  useEffect(() => {
    const data = location && location.state
    if ((data && data.completeStep !== null) && data.completeStep === "favorite_genres") {
      if (data.data && data.data.length > 0) {
        const genresResults = favoriteGenres && favoriteGenres.filter((elem) => data.data.find((id) => elem.id === id));
        // console.log(location.state.data, genresResults);
        setFavoriteGenres(genresResults);
      }
    } else {
      const genresResults = [
        {
          name: "Pop",
          icon: CloseIcon
        }, {
          name: "Indie",
          icon: CloseIcon
        }, {
          name: "Rock",
          icon: CloseIcon
        }, {
          name: "Rock",
          icon: CloseIcon
        }
      ]
      setFavoriteGenres(genresResults);
    } 
    if ((data && data.completeStep !== null) && data.completeStep === "favorite_artists") {
      if (data.data && data.data.length > 0) {
        const artistsResults = favoriteArtists && favoriteArtists.filter((elem) => data.data.find((id) => elem.id === id));
        // console.log(location.state.data, artistsResults);
        setFavoriteArtists(artistsResults);
      }
    } else {
      const artistsResults = [
        {
          name: "Taylor Swift",
          icon: CloseIcon
        }, {
          name: "Miley Cyrus",
          icon: CloseIcon
        }, {
          name: "Bon Jovi",
          icon: CloseIcon
        }, {
          name: "The Weeknd",
          icon: CloseIcon
        }
      ]
      setFavoriteArtists(artistsResults);
    }
  }, [favoriteGenres, favoriteArtists, location])
  const handleChange = (e, type, i) => {
    if (type === 'dob') {
      if (e) {
        let savingDate = new Date(e);
        savingDate.setHours(23, 59, 0, 0);
        setSelectedDate(savingDate);
      } else {
        setSelectedDate(null);
      }
    } else if (type === "favGenres") {
      const value = favoriteGenres.filter((item, idx) => idx !== i);
      setFavoriteGenres(value);
    } else if (type === "favArtists") {
      const value = favoriteArtists.filter((item, idx) => idx !== i);
      setFavoriteArtists(value);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  const submit = () => {
    var data = false;
    if (formData.publicname !== '') {
      const params = {
        name: formData.publicname,
      }
      userApi.setPublicProfileApi(params).then((res) => {
          // console.log(res);
          if (res.status) {
              if (res.message === "Name set") {
                data = true;
              }
          }
      });
    }
    if (selectedDate !== "") {
      const newDate = new Date(selectedDate);
      const date = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
      // console.log(newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate());
      const params = {
        birthdate: date,
      }
      userApi.setBirthdateApi(params).then((res) => {
          // console.log(res);
          if (res.status) {
              if (res.message === "Birthdate set") {
                data = true;
              }
          }
      });
    }
    if (favoriteGenres !== "") {
      const params = {
        genre: favoriteGenres,
      }
      userApi.setFavGenresApi(params).then((res) => {
          // console.log(res);
          if (res.status) {
              if (res.message === "Favorite genre set") {
                data = true;
              }
          }
      });
    }
    if (favoriteArtists !== "") {
      const params = {
        artist: favoriteArtists,
      }
      userApi.setFavArtistsApi(params).then((res) => {
        // console.log(res);
        if (res.status) {
            if (res.message === "Favorite artist set") {
              data = true;
            }
        }
      });
    }
    // if (formData.publicname !== '' && selectedDate !== "" && favoriteGenres !== "" && favoriteArtists !== "") {
    //   const params = {
    //     name: formData.publicname,
    //   }
    //   const result = await userApi.setPublicProfileApi(params)
    //   if (result.status) {
    //     const newDate = new Date(selectedDate);
    //     const date = newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate();
    //     // console.log(newDate.getFullYear() + "-" + (newDate.getMonth() + 1) + "-" + newDate.getDate());
    //     const params = {
    //       birthdate: date,
    //     }
    //     const result1 = await userApi.setBirthdateApi(params)
    //   // console.log(result1);
    //     if (result1.status) {
    //       const params = {
    //         genre: favoriteGenres,
    //       }
    //       const result2 = userApi.setFavGenresApi(params)
    //       if (result2.status) {
    //         const params = {
    //           artist: favoriteArtists,
    //         }
    //         const result3 = userApi.setFavArtistsApi(params)
    //         if (result3.status) {
    //           navigate("/profile");
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log(data);
    // if (data) {
    navigate("/profile")
    // }
  }
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && formData.publicname && formData.bio && !isTextareaFocused && selectedDate) {
      submit();
    } else if (e.key === "Escape") {
      navigate(-1);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [formData, isTextareaFocused, selectedDate]);
  const onblur = () => {
    setIsTextareaFocused(prevState => {
      return false;
    });
  }
  return (
    <MainContainer>
      <Background />
      <SubContainer>
        <Main className="padding-25 relative">
          <InnerHeader PageTitle={"Account Details"} helpIcon={false} />
          <Section>
            <Section className="mb-20">
              <Label lightwhite="true" className="account_label fontSemiBold">Public Name</Label>
              <Section className="relative">
                <Image src={UserCircleIcon} className="absolute w-auto top-22 left-14" />
                <Input autoComplete="off" name="publicname" className="inputBg mt-10 fontRegular fill_account" placeholder="Name" value={formData.publicname} onChange={(e) => handleChange(e, 'name')} />
                <Image src={EditPencilIcon} className="absolute w-auto top-22 right-14" />
              </Section>
            </Section>
            <Section className="mb-10">
              <Label lightwhite="true" className="account_label fontSemiBold">Bio</Label>
              <Section className="relative">
                <Textarea autoComplete="off" name="bio" className="inputBg mt-10 fontRegular fill_account_txt" placeholder="Bio" value={formData.bio} onChange={(e) => handleChange(e, 'bio')}
                  onFocus={() => setIsTextareaFocused(true)}
                  onBlur={onblur} />
                <Image src={EditPencilIcon} className="absolute w-auto top-22 right-14" />
              </Section>
            </Section>
            <Section className="mb-30">
              <Label lightwhite="true" className="account_label fontSemiBold">Birthdate</Label>
              <Section className="relative births">
                <Image src={CalendarBlankIcon} className="absolute w-auto top-22 left-14" />
                <DatePicker
                  wrapperClassName="inputBg mt-10 fontRegular fill_account"
                  autoComplete="off"
                  maxDate={new Date()}
                  selected={selectedDate}
                  showMonthDropdown={true}
                  popperClassName="bg-getstartgray text-lightwhite inputBg mt-10 fontRegular fill_account"
                  showYearDropdown={true}
                  icon={
                    <img
                      src={CalendarBlankIcon}
                      className="absolute w-auto top-22 left-14"
                      alt="Custom Icon"
                    />
                  }
                  onChange={(e) => {
                    handleChange(e, 'dob')
                  }}
                  placeholderText={'MM/DD/YYYY'}
                  toggleCalendarOnIconClick
                  showIcon
                  disabledKeyboardNavigation
                  name="dob"
                />
                <Image src={EditPencilIcon} className="absolute w-auto top-22 right-14" />
              </Section>
            </Section>
            <Section className="mb-20">
              <Label lightwhite="true" className="account_label fontSemiBold mb-20">Favorite Genres</Label>
              <Ul className="p-0 mb-0 mt">
                {favoriteGenres.map((item, i) => {
                  return (
                    <Li className="mb-10 mr-10 generes_inline" key={i}>
                      <Background className="gen_bg radius-4">
                        <LinkText className="gen_pd fontSemiBold">{item.name}<Image className="vertical-middle ml-5" name={item.name} src={item.icon} onClick={(e) => handleChange(e, "favGenres", i)} /></LinkText>
                      </Background>
                    </Li>
                  )
                })}
              </Ul>
              <DefaultButton onClick={() => navigate("/favorite-genres", { state: { type: "account-details" }})} className="text-small-base fontSemiBold skipin custom_bottom mt-10">Add more</DefaultButton>
            </Section>
            <Section className="mt-20 mb-150">
              <Label lightwhite="true" className="account_label fontSemiBold mb-20">Favorite Artists</Label>
              <Ul className="p-0 mb-0 mt">
                {favoriteArtists.map((item, i) => {
                  return (
                    <Li className="mb-10 mr-10 generes_inline" key={i}>
                      <Background className="gen_bg radius-4">
                        <LinkText className="gen_pd fontSemiBold">{item.name}<Image className="vertical-middle ml-5" name={item.name} src={item.icon} onClick={(e) => handleChange(e, "favArtists", i)} /></LinkText>
                      </Background>
                    </Li>
                  )
                })}
              </Ul>
              <DefaultButton onClick={() => navigate("/favorite-artists", { state: { type: "account-details" }})} className="text-small-base fontSemiBold skipin custom_bottom mt-10">Add more</DefaultButton>
            </Section>
            <Section>
              <DefaultButton className={`text-small-base fontSemiBold custom_bottom ${!(
                formData.publicname &&
                formData.bio &&
                selectedDate &&
                favoriteGenres &&
                favoriteArtists &&
                !isTextareaFocused
              ) ? "disable" : ""}`} disabled={!(
                formData.publicname &&
                formData.bio &&
                selectedDate &&
                favoriteGenres &&
                favoriteArtists &&
                !isTextareaFocused
              )} onClick={submit}>Done</DefaultButton>
            </Section>
          </Section>
        </Main>
      </SubContainer>
    </MainContainer>
  );
}

export default AccountDetails;