import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import BackIcon from '../assets/images/back.svg';
import OnboardingStarIcon from '../assets/images/onboarding_star.svg';
import CaretRightIcon from '../assets/images/caretright.svg';
import DashShieldStarIcon from '../assets/images/dash_shield_star.svg';
import DashChampagneIcon from '../assets/images/dash_champagne.svg';
import DashCirclesThreeIcon from '../assets/images/dash_circles_three.svg';
import DashQrCodeIcon from '../assets/images/dash_qr_code.svg';
import DashJarLabelIcon from '../assets/images/dash_jar_label.svg';
import DashCheckCircleIcon from '../assets/images/dash_check_circle.svg';
import { H4, H5, Paragraph, LinkText, Ul, Li, Span } from "../components/Text";

function Dashbash() { 
  const navigate = useNavigate();
  return (
    <MainContainer>
      <Background />
      <SubContainer>
        <Main className="padding-25">
          <Section className="text-center relative mb-30">
            <Link to="/updates" className="absolute top-12 left-0"><Image src={BackIcon} /></Link>
            <H4 white="true" className="mt-15">Dash Bash - New York</H4>
          </Section>
          <Section className="displayflex content-between mb-20">
            <Paragraph lightwhite="true" className="opacity-70 dash_total_points">Total points won</Paragraph>
            <Paragraph white="true" className="divide_points fontSemiBold">10/150</Paragraph>
          </Section>
          <Section>
            <Ul className="p-0 mb-0">
              <Li className="block mb-10">
                <Background className="email_block padding-16 radius-4">
                  <Section className="displayflex relative" onClick={() => navigate("/order-claimCollectible")}>
                    <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={DashShieldStarIcon} /></Background></Section>
                    <Section className="w-auto pl-10">
                      <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Attend & Claim a collectible</H5>
                      <Paragraph white="true" className="opacity-50 mt-5">
                        <Image className="mr-5" src={OnboardingStarIcon} /><Span>10 points</Span>
                      </Paragraph>
                    </Section>
                    <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                  </Section>
                </Background>
              </Li>
              <Li className="block mb-10">
                <Background className="email_block_active padding-16 radius-4">
                  <Section className="displayflex relative" onClick={() => navigate("/order")}>
                    <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={DashChampagneIcon} /></Background></Section>
                    <Section className="w-auto pl-10">
                      <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Order a Red Wine</H5>
                      <Paragraph white="true" className="opacity-50 mt-5">
                        <Image className="mr-5" src={OnboardingStarIcon} /><Span className="text-line">10 points</Span> <Span>Done!</Span>
                      </Paragraph>
                    </Section>
                    <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                  </Section>
                </Background>
              </Li>
              <Li className="block mb-10">
                <Background className="email_block padding-16 radius-4">
                  <Section className="displayflex relative" onClick={() => navigate("/order-dashbash")}>
                    <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={DashCirclesThreeIcon} /></Background></Section>
                    <Section className="w-auto pl-10">
                      <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Order any 3 items</H5>
                      <Paragraph white="true" className="opacity-50 mt-5">
                        <Image className="mr-5" src={OnboardingStarIcon} /><Span>10 points</Span> <Background className="dashbashes ml-5"><Span className="opacity-30">1/3 done</Span></Background>
                      </Paragraph>
                    </Section>
                    <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                  </Section>
                </Background>
              </Li>
              <Li className="block mb-10">
                <Background className="email_block padding-16 radius-4">
                  <Section className="displayflex relative">
                    <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={DashQrCodeIcon} /></Background></Section>
                    <Section className="w-auto pl-10">
                      <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Scan hidden QR Codes</H5>
                      <Paragraph white="true" className="opacity-50 mt-5">
                        <Image className="mr-5" src={OnboardingStarIcon} /><Span>10 points</Span> <Background className="dashbashes ml-5"><Span className="opacity-30">1/3 done</Span></Background>
                      </Paragraph>
                    </Section>
                    <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                  </Section>
                </Background>
              </Li>
              <Li className="block mb-10">
                <Background className="email_block padding-16 radius-4">
                  <Section className="displayflex relative">
                    <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={DashJarLabelIcon} /></Background></Section>
                    <Section className="w-auto pl-10">
                      <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Order a pitcher</H5>
                      <Paragraph white="true" className="opacity-50 mt-5">
                        <Image className="mr-5" src={OnboardingStarIcon} /><Span>10 points</Span>
                      </Paragraph>
                    </Section>
                    <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                  </Section>
                </Background>
              </Li>
              <Li className="block mb-10">
                <Background className="email_block padding-16 radius-4">
                  <Section className="displayflex relative">
                    <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={DashCheckCircleIcon} /></Background></Section>
                    <Section className="w-auto pl-10">
                      <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Finish all above & <Span className="displayblock collects">get a collectible</Span></H5>
                      <Paragraph white="true" className="opacity-50 mt-5">
                        <Image className="mr-5" src={OnboardingStarIcon} /><Span>10 points</Span>
                      </Paragraph>
                    </Section>
                    <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                  </Section>
                </Background>
              </Li>
            </Ul>
          </Section>
        </Main>
      </SubContainer>
    </MainContainer>
  );
}

export default Dashbash;