/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { MainContainer, SubContainer, Main, Image } from "../components/Styles";
import styled from "styled-components";
import { Background } from "../components/Background";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BasicTier from "../assets/images/basic-tier.svg"
import { Paragraph, Span, H1 } from "../components/Text";
import Document from "../assets/images/document.svg";
import Shield from "../assets/images/shield.svg";
import ButtonRight from "../assets/images/button-arrow-right.svg"
import arrowRight from "../assets/images/arrow-right.svg";
import GrayStar from "../assets/images/star-gray.svg"
import PlusCircle from "../assets/images/plus-circle.svg"
import PremiumCircle from "../assets/images/premium-circle.svg"
import UltraCircle from "../assets/images/ultra-circle.svg"
import AlistCircle from "../assets/images/alist-circle.svg"
import { DefaultButton } from "../components/DefaultButton"
import GaugeComponent from 'react-gauge-component'
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const VaultContainer = styled.div`
    .tier-sub{
    background:rgba(255, 255, 255, 0.05);
    }

    .collectible{
        width:49%;

        .collectible-sub{
            background:var(--bg-perks);
            border-radius:4px;
            padding:15px;
            margin:8px;
        }
    }
`;


function Vault() {
  const [value, setValue] = React.useState(0)
  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(200)
  const [searchParams] = useSearchParams();
  const [subArcs, setSubArcs] = React.useState([])
  const [nesxtTier, setNextTier] = React.useState("PLUS")
  const [type, setType] = React.useState("basic")
  const [tier, setTier] = React.useState("BASIC")
  const navigate = useNavigate();   
  const getColor = (limit) => {
    return value >= limit ? "#fff" : "#888";
  };
  React.useEffect(() => {
    const type = searchParams.get('tier');
    setType(type ? type : "basic");
    if (type === "plus") {
      setTier("PLUS")
      setValue(650);
      setNextTier("PREMIUM");
      setMax(900);
      setSubArcs([
        { limit: 150, color: getColor(150), showTick: true },
        { limit: 300, color: getColor(300), showTick: true },
        { limit: 450, color: getColor(450), showTick: true },
        { limit: 600, color: getColor(600), showTick: true },
        { limit: 750, color: getColor(750), showTick: true },
        { limit: 900, color: getColor(900), showTick: true },
      ]);
    } else if (type === "premium" || type === "ultra") {
      setTier("PREMIUM")
      setNextTier("ULTRA");
      setMax(5000);
      setValue(3650);
      setSubArcs([
        { limit: 800, color: getColor(800), showTick: true },
        { limit: 1600, color: getColor(1600), showTick: true },
        { limit: 2500, color: getColor(2500), showTick: true },
        { limit: 3300, color: getColor(3300), showTick: true },
        { limit: 4100, color: getColor(4100), showTick: true },
        { limit: 5000, color: getColor(5000), showTick: true },
      ]);
    } else if (type === "alist") {
      setValue(6000);
      setTier("A-LIST")
    } else {
      setValue(155);
      setMax(200);
      setSubArcs([
        { limit: 35, color: getColor(35), showTick: true },
        { limit: 70, color: getColor(70), showTick: true },
        { limit: 100, color: getColor(100), showTick: true },
        { limit: 135, color: getColor(135), showTick: true },
        { limit: 170, color: getColor(170), showTick: true },
        { limit: 200, color: getColor(200), showTick: true },
      ]);
    }

  }, [value])
  const pointsHistory = () => {
    navigate("/points-history")
}
  return (

    <MainContainer>
      <VaultContainer>

        <Background />

        <Main className={`${type}-tier`}>
        <SubContainer className="pt-80">
            <Header vault={true} profileIcon={true} settingIcon={true} tier={tier} />
          


            <Main className="pr-15 pl-15 pb-15">
              <Main className="tier-sub p-15 pt-20 z-10 relative">

                <Main className="flex content-between">
                  <Main>
                    <Paragraph className="flex items-center mt-0">
                      <Image src={GrayStar} />
                      <Span className="pl-10" textGray="true">Points Balance</Span>
                    </Paragraph>
                    <H1 white="true" className="mt-5">{value}</H1>
                  </Main>

                  <Main>
                    <DefaultButton className="point_history flex items-center" onClick={pointsHistory}>
                      <Span className="pr-5">Points History</Span>
                      <Image src={ButtonRight} className="cursor-pointer pl-10 pr-0" />
                    </DefaultButton>
                  </Main>
                </Main>

                <Main className="flex nowrap overflow-x scroll-bar pb-10">
                  <DefaultButton className="flex vault_scroll_Btn mr-10">
                    <Image src={Document} className="pr-10" />
                    Read-access to exclusive News
                  </DefaultButton>


                  <DefaultButton className="flex vault_scroll_Btn mr-10">
                    <Image src={Document} className="pr-10" />
                    Read-access to exclusive News
                  </DefaultButton>

                  <DefaultButton className="flex vault_scroll_Btn mr-10">
                    <Image src={Document} className="pr-10" />
                    Read-access to exclusive News
                  </DefaultButton>
                </Main>
                {type !== "alist" && (
                  <>
                    <Main className="pt-20 relative vault-one">
                      {/* <GaugeComponent
  arc={{
    subArcs: [
      {
        limit: 20,
        color: '#fff',
        showTick: true
      },
      {
        limit: 40,
        color: '#fff',
        showTick: true
      },
      {
        limit: 60,
        color: '#fff',
        showTick: true
      },
      {
        limit: 80,
        color: '#fff',
        showTick: true
      },
      {
        limit: 100,
        color: '#fff',
        showTick: true
      },
    ],    
    width:0.05,
    cornerRadius:5,
    color: '#ccc'
  }}
  value={50}

  labels={{
    valueLabel: { formatTextValue: value => value + '.' },
    tickLabels: {
      type: 'outer',
      valueConfig: { formatTextValue: '.', fontSize: 10 },
    }
  }}
  type="semicircle"
  
/> */}


                      <GaugeComponent
                        type="semicircle"
                        arc={{
                          // width: 0.2,
                          padding: 0.02,
                          // cornerRadius: 1,
                          //gradient: true,
                          subArcs,

                          width: 0.05,
                          cornerRadius: 5,
                        }}
                        pointer={{
                          color: '#ccc',
                          baseColor: 'var(--bg-gray-color)',
                          type: "blob", animationDelay: 0,
                          width: 8,
                          // elastic: true,
                        }}
                        labels={{
                          valueLabel: { formatTextValue: value => `` },
                          tickLabels: {
                            type: 'outer',
                            valueConfig: { formatTextValue: value => value + 'ºC', fontSize: 10 },
                          }
                        }}

                        value={value}
                        minValue={min}
                        maxValue={max}
                      />

                      <Main className="gauge-center-message">
                        <Span gray="true" className="text-xs">Next tier</Span>
                        <H1>{nesxtTier}</H1>
                        <Paragraph gray="true">You need 60 more points to upgrade. Keep going!</Paragraph>
                      </Main>


                    </Main>
                  </>
                )}

              </Main>
            </Main>

            {type === "alist" && (
              <Image src={AlistCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
            )}
            {type === "plus" && (
              <Image src={PlusCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
            )}
            {type === "premium" && (
              <Image src={PremiumCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
            )}
            {type === "basic" && (
              <Image src={BasicTier} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
            )}
            {type === "ultra" && (
              <Image src={UltraCircle} className="ultraGradient w-full absolute left-0 right-0 bottom-0" />
            )}

          </SubContainer>
        </Main>

        <SubContainer className="pb-100" >

          <Main className="p-15 flex items-center content-between">
            <Paragraph textGray="true">YOUR COLLECTIBLES</Paragraph>
            <Image src={arrowRight} className="cursor-pointer" />
          </Main>

          <Main className="p-15 flex flex-wrap pt-0">

            <Main className="collectible">
              <Main className="collectible-sub">
                <Image src={Shield} />
                <Paragraph white="true">
                  Collect at least
                  3 collectibles
                </Paragraph>

                <Paragraph className="flex items-center">
                  <Image src={GrayStar} />
                  <Span className="text-line pl-10" textGray="true">+10 Points</Span>
                </Paragraph>
              </Main>
            </Main>

            <Main className="collectible">
              <Main className="collectible-sub">
                <Image src={Shield} />
                <Paragraph white="true">
                  Collect at least
                  3 collectibles
                </Paragraph>

                <Paragraph className="flex items-center">
                  <Image src={GrayStar} />
                  <Span className="text-line pl-10" textGray="true">+10 Points</Span>
                </Paragraph>
              </Main>
            </Main>

            <Main className="collectible">
              <Main className="collectible-sub">
                <Image src={Shield} />
                <Paragraph white="true">
                  Collect at least
                  3 collectibles
                </Paragraph>

                <Paragraph className="flex items-center">
                  <Image src={GrayStar} />
                  <Span className="text-line pl-10" textGray="true">+10 Points</Span>
                </Paragraph>
              </Main>
            </Main>



          </Main>

          <Footer />
        </SubContainer>
      </VaultContainer>
    </MainContainer>

  );
}

export default Vault;