//Lets not have that email verification for now. Just a simple email input and a button to navigate to the next page, the set password page. Let's just assume all users have verified their emails.
/* eslint-disable no-unused-vars */

import React from "react";
import { MainContainer, SubContainer, Main, LeftArrow, Input, Section, Image } from "../components/Styles";
import LeftArrowIcon from '../assets/images/left-arrow.svg';
import emailIcon from '../assets/images/email.svg';

import MailImage from '../assets/images/mail-round.svg';
import SuccessImage from '../assets/images/success-round.svg';
import CancelImage from '../assets/images/cancel-round.svg';
import { Background } from "../components/Background";
import { DefaultButton } from "../components/DefaultButton";
import { Paragraph, H1 } from "../components/Text";
import { useNavigate, useLocation } from "react-router-dom";
import userApi from "../utils/userApi";

function Signup() {
    const [email, setEmail] = React.useState("")
    const [validEmail, setValidEmail] = React.useState(false)
    const [verifyMail] = React.useState(false)
    const [verification1, setVerification1] = React.useState(false)
    const [verification2, setVerification2] = React.useState(false)
    const [verification3, setVerification3] = React.useState(false)
    const [type, setType] = React.useState("signup")
    const [error, setError] = React.useState("")
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;

    React.useEffect(() => {
        if (state && state.type === 'forget') {
            setType("forget")
        }
    }, [state, navigate]);

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        const emailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
        if (emailRegex.test(value)) {
            setValidEmail(true);
        } else {
            setValidEmail(false);
        }
    }
    const handleClick = () => {
        // setVerifyMail(true)
        // setVerification1(true)
        const params = {
            email,
        }
        userApi.getEmailExistsApi(params).then((res) => {
            // console.log(res);
            if (res.status) {
                navigate('/new-password', {
                    state: { 
                        from: type,
                        email: email
                    }
                });
            } else {
                setError(res.error);
                // console.log(res.error);
            }
        });
    }

    const confirmLink = (step) => {
        if (step === "1") {
            setVerification1(false)
            setVerification2(true)
            setVerification3(false)
        } else if (step === "2") {
            setVerification2(false)
            setVerification3(true)
        } else if (step === "3") {
            navigate('/new-password', {
                state: { 
                    from: type
                }
            });
        }
    }
    const back = () => {
        navigate("/welcome");  
    }
    const handleKeyDown = (e) => {
        if (e.key === "Enter" && validEmail) {
            handleClick();
        }
    };

    return (
        <MainContainer>
            <Background />
            <SubContainer >
                {!verifyMail && (
                    <>
                        <Main className="padding-25">
                            <LeftArrow onClick={back}src={LeftArrowIcon} className="mb-10" />
                            <H1 white="true" className="mb-0 letter-spacing">{type === "signup" ? "Signup using your Email" : "Forgot your password?"}</H1>
                            <br />
                            <Section className="mb-20">
                                <Section className="relative">
                                    <Image src={emailIcon} className="absolute w-auto top-25 left-20" />
                                    <Input className="inputBg mt-10 fontRegular" placeholder="Email" value={email} onChange={(e) => {
                                        handleChange(e)
                                    }} onKeyDown={handleKeyDown} />
                                </Section>
                                {error !== "" && (
                                    <Paragraph red="true">
                                        {error}
                                    </Paragraph>
                                )}
                            </Section>
                        </Main>

                        <DefaultButton
                            className={`absolute left-20 right-20 bottom-20 w-auto ${validEmail ? "" : "disable"}`}
                            onClick={handleClick}
                            disabled={!validEmail}
                        >
                            Continue
                        </DefaultButton>
                    </>
                )}




                {verification1 && !verification2 && !verification3 && (
                    <>
                        <Main className="padding-25 text-center flex item-center content-center flex-column">
                            <Image src={MailImage} className="passwordChangeLogo block" />
                            <Main className="padding-25">
                                <Paragraph gray="true" className="fontNormal mt-10 mb-10">
                                    You’ll have received a verification link{"\n"} in your email. Click ‘confirm’ to set a{"\n"} password and create your account
                                </Paragraph>
                            </Main>
                        </Main>
                        <DefaultButton onClick={() => confirmLink("1")} className="absolute left-20 right-20 bottom-20 w-auto">Confirm</DefaultButton>
                    </>
                )}

                {!verification1 && verification2 && !verification3 && (
                    <>
                        <Main className="padding-25 text-center flex item-center content-center flex-column">
                            <Image src={CancelImage} className="passwordChangeLogo block" />

                            <Main className="padding-25">
                                <Paragraph gray="true" className="fontNormal mt-10 mb-10 break-spaces">You don’t seem to have clicked the verification link. Can you try again?</Paragraph>
                            </Main>
                        </Main>

                        <DefaultButton onClick={() => confirmLink("2")} className="absolute left-20 right-20 bottom-20 w-auto">Confirm Again</DefaultButton>
                    </>)}


                {!verification1 && !verification2 && verification3 && (
                    <>
                        <Main className="padding-25 text-center flex item-center content-center flex-column">
                            <Image src={SuccessImage} className="passwordChangeLogo block" />

                            <Main className="padding-25">
                                <Paragraph gray="true" className="fontNormal mt-10 mb-10 break-spaces size-14">Your email has been successfully{"\n"} verified. We’ll ask you to create a{"\n"} password next.</Paragraph>
                            </Main>
                        </Main>

                        <DefaultButton onClick={() => confirmLink("3")} className="absolute left-20 right-20 bottom-20 w-auto">Next</DefaultButton>
                    </>
                )}
            </SubContainer>
        </MainContainer>
    );
}

export default Signup;
