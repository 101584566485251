import React from "react";
import {  MainContainer, SubContainer, Main, Section, Input } from "../components/Styles";
import { Background } from "../components/Background";
import { H5, Label, Paragraph, Span } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";

function Notification() {
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25">
           <InnerHeader PageTitle={"Account Details"} helpIcon={false} />              
            <Section>
             <Section className="pb-150">
              <Section className="displayflex content-between self-start notificaion_list pb-20 mb-20"> 
               <Section className="w-auto">
                <H5 lightwhite="true" className="mt-0 mb-15 opacity-70">Send updates</H5>
                <Paragraph lightwhite="true" className="opacity-30 notify_txt mt-0">If this is turned on, we'll send you important emails related to your account</Paragraph>
               </Section>
               <Section className="w-auto">
                <Label className="switch">
                 <Input className="switchin" type="checkbox" />
                 <Span className="sliders"></Span>
                </Label>
               </Section>
              </Section>
              <Section className="displayflex content-between self-start notificaion_list pb-20 mb-20"> 
               <Section className="w-auto">
                <H5 lightwhite="true" className="mt-0 mb-15 opacity-70">Send promotional emails</H5>
                <Paragraph lightwhite="true" className="opacity-30 notify_txt mt-0">If this is turned on, we'll send you important emails related to your account</Paragraph>
               </Section>
               <Section className="w-auto">
                <Label className="switch">
                 <Input className="switchin" type="checkbox" />
                 <Span className="sliders"></Span>
                </Label>
               </Section>
              </Section>
             </Section>
             <Section className="absolute w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto">
              <DefaultButton className="text-small-base fontSemiBold custom_bottom">Done</DefaultButton>
             </Section>
            </Section>
           </Main>
          </SubContainer>
        </MainContainer>  
    );
    }

export default Notification;