import React, { useState } from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsLargeImg from '../assets/images/news_large.svg';
import MappInIcon from '../assets/images/mappin.svg';
import CalendarIcon from '../assets/images/calendar.svg';
import StarInIcon from '../assets/images/starin.svg';
import ShieldStarIcon from '../assets/images/shieldstar.svg';
import CheckLargerIcon from '../assets/images/check_larger.svg';
import Header from "../components/Header";
import { H2, H4, Paragraph, Ul, Li } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";

function News() {
  const [registerSuccessPopup, setRegisterSuccessPopup] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
var settings = {
    dots: false,
    centerMode: true,
    centerPadding: '25px',
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1
  };  
    return (
        <MainContainer>
         <Background/>
          <SubContainer className="pt-80">

          <Header backIcon={true} settingIcon={true} />

           <Main className="padding-25 relative">
            <Section>
             <Slider className="sets" {...settings}>
              <Section className="displayblock overflowhidden outlinenone boxshadownone">
               <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
              </Section>
              <Section className="displayblock overflowhidden outlinenone boxshadownone">
               <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
              </Section>
              <Section className="displayblock overflowhidden outlinenone boxshadownone">
               <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
              </Section>
             </Slider>
            </Section>
            <Section className="p-15 news-auto">
             <H4 white="true" className="fontSemiBold mt-0 mb-10">Dash Bash - New York</H4>
             <Paragraph white="true" className="opacity-60 lineheight-18 mt-0">Located at Hauz Khas in Delhi, the Bar/Pub Imperfecto is easily  accessible to all your guests. In business since 2010 Imperfecto is one  of the most popular event venues in city. Imperfecto has been a prime  location to celebrate Birthday Party, Bachelor Party, Social Gathering  and many other events. Imperfecto can accommodate between 10 - 50 guests  at a time. Imperfecto provides numerous facilities like Power Backup,  Air Condition, DJ and many more which ensures that your event is  successful.</Paragraph>
             <Section className="mt-35 mb-80">
              <Ul>
               <Li className="block mb-15 dash_news opacity-60"><span className="inline-block width-30"><Image className="vertical-middle" src={MappInIcon} /></span><span>JBJ Bar, Austin, Texas</span></Li>
               <Li className="block mb-15 dash_news opacity-60"><span className="inline-block width-30"><Image className="vertical-middle" src={CalendarIcon} /></span><span>24-31 July</span></Li>
               <Li className="block mb-15 dash_news opacity-60"><span className="inline-block width-30"><Image className="vertical-middle" src={StarInIcon} /></span><span>Upto 150 Points</span></Li>
               <Li className="block mb-15 dash_news opacity-60"><span className="inline-block width-30"><Image className="vertical-middle" src={ShieldStarIcon} /></span><span>2 Collectibles</span></Li>
              </Ul>
            </Section> 
            </Section>
            <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-560 m-auto"> 
            {alreadyRegistered === false && (
             <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom" onClick={() => setRegisterSuccessPopup(true)}>Register for the Event</DefaultButton>
            )}
             {alreadyRegistered === true && (
              <DefaultButton disabled={true} className="mb-10 text-small-base fontSemiBold custom_bottom disable_dark">Already Registered</DefaultButton>
              )}
            </Section>
            {registerSuccessPopup === true && (
            <Background className="popup_confrim_news left-0 right-0 m-auto top-0 max-590">
             <Background className="confirm_inners_pop_one" />   
              <Section className="inner_popup_center displaytable h-full"> 
               <Section className="inner_popup_middle relative zindex displaytablecell vertical-middle pl-15 pr-15">
                <Background className="inner_popup_transparent text-center">
                 <Image className="mb-15" src={CheckLargerIcon} />
                 <H2 lightwhite="true" className="proceed_titleall">You've successfully <span className="displayblock">registered for</span> 'Dash Bash - New York'</H2>
                 <Paragraph lightwhite="true" className="opacity-70 proceed_sub location_desc s-13 fontRegular font-Regular">50 points have been successfully been awarded to you</Paragraph>
                 <Section className="displayflex mt-20 absolute left-0 bottom-20"> 
                  <Section className="pl-15 pr-15">
                   <DefaultButton className="text-small-base fontSemiBold custom_bottom" onClick={() => {
                    setAlreadyRegistered(true)
                    setRegisterSuccessPopup(false)
                    }}>Awesome</DefaultButton>
                  </Section>
                 </Section> 
                </Background>
               </Section>
              </Section>  
            </Background>   
            )}
          </Main>
         </SubContainer>
        </MainContainer>   
    );
    }

export default News;