import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MainContainer, SubContainer, Main, Section, Image, Input, BackgroundImg } from "../components/Styles";
import { Background } from "../components/Background";
import { H2, Label, Paragraph } from "../components/Text";
import SearchIcon from '../assets/images/search.svg';
import FavLargeIcon from '../assets/images/fav_large.svg';
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";
import { getUserData } from "../utils/authUtils";
import userApi from "../utils/userApi";

function FavoriteArtists() {
  const navigate = useNavigate();
  const location = useLocation();
  const allArtistsItems = useMemo(() => {
    return [
      { id: 1, name: 'Taylor Swift' },
      { id: 2, name: 'Ariana Grande' },
      { id: 3, name: 'Beyonce' },
      { id: 4, name: 'The Weeknd' },
      { id: 5, name: 'Drake' },
      { id: 6, name: 'Ed Sheeran' },
      { id: 7, name: 'Eminem' },
      { id: 8, name: 'Shakira' },
      { id: 9, name: 'Lana Del Rey' },
      { id: 10, name: 'NewJeans' },
      { id: 11, name: 'BTS' },
      { id: 12, name: 'Lady Gaga' },
      { id: 13, name: 'Madonna' },
      { id: 14, name: 'Miley Cyrus' },
      { id: 15, name: 'Harry Styles' },
      { id: 16, name: 'Jennifer Lopez' },
      { id: 17, name: 'Imagine Dragons' },
      { id: 18, name: 'Stray Kids' },
      { id: 19, name: 'Bruno Mars' },
      { id: 20, name: 'Karol G' },
      { id: 21, name: 'BLACKPINK' },
      { id: 22, name: 'Kelly Clarkson' },
      { id: 23, name: 'Glass Animals' },
      { id: 24, name: 'Kanye West' },
      { id: 25, name: 'XG' },
      { id: 26, name: 'NCT' },
      { id: 27, name: 'City Girls' },
      { id: 28, name: 'Charli XCX' },
      { id: 29, name: 'Coldplay' },
      { id: 30, name: 'Travis Scott' },
      { id: 31, name: 'Green Day' },
      { id: 32, name: 'The Last Dinner Party' },
      { id: 33, name: 'The Rolling Stones' },
      { id: 34, name: 'Foo Fighters' },
      { id: 35, name: 'Red Hot Chili Peppers' },
      { id: 36, name: 'Brennan Heart' },
      { id: 37, name: 'Les Attitudes Spectrales' },
      { id: 38, name: 'Maustetytöt' },
      { id: 39, name: 'Zoster' },
      { id: 40, name: 'Korn' },
      { id: 41, name: 'Selena Gomez' },
      { id: 42, name: 'Katy Perry' },
      { id: 43, name: 'Spiritbox' },
      { id: 44, name: 'Deap Vally' },
      { id: 45, name: 'Sacred Reich' },
      { id: 46, name: 'Chevelle' },
      { id: 47, name: 'Toadies' },
      { id: 48, name: 'Sugababes' },
      { id: 49, name: 'Nasty Savage' },
      { id: 50, name: 'Testament' },
      { id: 51, name: 'Buffalo Tom' }];
  }, []);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredItems, setFilteredItems] = useState(allArtistsItems);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activeParentIds, setActiveParentIds] = useState([]);
  const [path, setPath] = useState('');
  // Initialize selectedItems with the first item checked
  useEffect(() => {
    if (allArtistsItems.length > 0) {
      setSelectedItems([allArtistsItems[0].id]);
      setActiveParentIds([allArtistsItems[0].id]); // Set the first item's parent as active initially
    }
    if (location && location.state && location.state.type) {
      const path = `${"/" + location.state.type}`
      // console.log(path);
      setPath(path);
    }
  }, [allArtistsItems, location])

  // Function to split items into alternating groups of 3 and 2
  const splitItems = () => {
    const groupedItems = [];
    let currentIndex = 0;

    while (currentIndex < filteredItems.length) {
      // First group of 3 items
      groupedItems.push(filteredItems.slice(currentIndex, currentIndex + 3));
      currentIndex += 3;

      // Second group of 2 items
      if (currentIndex < filteredItems.length) {
        groupedItems.push(filteredItems.slice(currentIndex, currentIndex + 2));
        currentIndex += 2;
      }
    }

    return groupedItems;
  };
  // Function to handle search input change
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    // Filter items based on search term
    const filteredItems = allArtistsItems.filter(item =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredItems(filteredItems);

    // Ensure selectedItems maintains the first item checked if it matches the search
    // if (filteredItems.length > 0 && !filteredItems.some(item => selectedItems.includes(item.id))) {
    //   setSelectedItems([filteredItems[0].id]);
    //   setActiveParentIds([filteredItems[0].id]); // Set the first item's parent as active when filtered
    // }
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event, itemId) => {
    if (event.target.checked) {
      setSelectedItems([...selectedItems, itemId]);
      setActiveParentIds([...activeParentIds, itemId]);
    } else {
      setSelectedItems(selectedItems.filter(selectedItemId => selectedItemId !== itemId));
      setActiveParentIds(activeParentIds.filter(activeParentId => activeParentId !== itemId));
    }
  };

  // Function to handle Confirm
  const handleConfirm = () => {
    // have to check 2 selection then open popup
    if (selectedItems.length < 2) {
      setIsConfirmed(true)
    } else {
      const userID = getUserData();
      const params = {
        userID: userID,
        artist: selectedItems,
      }
      userApi.setFavArtistsApi(params).then((res) => {
        // console.log(res);
        if (res.status) {
            if (res.message === "Favorite artist set") {
                navigate(path, { state : { completeStep: "favorite_artists", data: selectedItems}})
            }
        }
      });
    }
  }
  return (
    <MainContainer>
      <Background />
      <SubContainer>
        <Main className="padding-25 relative pb-150">

          <InnerHeader PageTitle={"Favorite Artists"} helpIcon={true} helpLink={"/public-profile"} />

          <Section>
            <Section className="mb-40">
              <Section className="relative">
                <Image src={SearchIcon} className="absolute w-auto top-25 left-14" />
                <Input className="inputBg mt-10 fontRegular full_inname" 
                placeholder="Search for your artists"
                value={searchTerm}
                onChange={handleSearchChange} />
              </Section>
              {splitItems().map((group, index) => (
                <Section key={index} className={group.length === 3 ? "relative list-three mt-20 displayflex" : "relative list-two mt-20 displayflex"}>
                  {group.map((item, idx) => (
                    <Section key={item.id} className={group.length === 3 ? `cm-4 ${idx === 1 ? 'pl-12 pr-12' : ''}` : `cm-6 ${idx === 0 ? 'pr-12' : ''}`}>
                      <Background className={activeParentIds.includes(item.id) ? "w-full static_box_in_enable radius-4" : "w-full static_box_in radius-4"}>
                        <Label white="true" className="pt-5 location_txt_lg text-center">
                          {item.name}
                          <Input className="list_check_fill locationcheck absolute left-0 top-0 m-0 radius-4" type="checkbox" checked={selectedItems.includes(item.id)} onChange={(e) => handleCheckboxChange(e, item.id)} />
                          <BackgroundImg className="add_checktick absolute left-10 bottom-10"></BackgroundImg>
                        </Label>
                      </Background>
                    </Section>
                  ))}
                </Section>
              ))}
              {/* Display message if no items match the search */}
              {filteredItems.length === 0 && (
                <p>No Artists found.</p>
              )}
            </Section>
          </Section>
          <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto">
            <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom" onClick={() => handleConfirm()}>Confirm</DefaultButton>
            <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom" onClick={() => navigate(-1)}>Go Back</DefaultButton>
          </Section>
          {isConfirmed === true && (
            <Background className="popup_confrim left-0 right-0 m-auto top-0 max-590">
              <Background className="confirm_inners_pop" />
              <Section className="inner_popup_center displaytable h-full">
                <Section className="inner_popup_middle relative zindex displaytablecell vertical-middle pl-15 pr-15">
                  <Background className="inner_popup_bg text-center pt-32 pb-20 pl-16 pr-16 radius-4">
                    <Image className="mb-15" src={FavLargeIcon} />
                    <H2 lightwhite="true" className="proceed_title_generes">You need to select at least 2 artists for your profile</H2>
                    <Paragraph lightwhite="true" className="opacity-70 proceed_sub location_desc">Don’t worry, we don’t share any of your private info with other members</Paragraph>
                    <Section className="displayflex mt-20">
                      <DefaultButton className="text-small-base fontSemiBold custom_bottom" onClick={() => setIsConfirmed(false)}>Okay</DefaultButton>
                    </Section>
                  </Background>
                </Section>
              </Section>
            </Background>
          )}
        </Main>
      </SubContainer>
    </MainContainer>
  );
}

export default FavoriteArtists;