import styled from 'styled-components';

export const Background = styled.div`
    position:fixed;
    background:var(--bg-gray-color);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    &.vip-section-box {
       background:var(--bg-vip-sec);
       position:static;
       height:auto;
       width:auto;
       display:block;
    }
    &.perks-box {
       background:var(--bg-perks);
       position:static;
       height:auto;
       width:auto;
       display:block;
    }
    &.vip-plus {
       background:var(--bg-vip-plus);
       position:relative;
       height:auto;
       width:auto;
       display:block;
       overflow:hidden;
    }
    &.vip-premium {
       background:var(--bg-vip-premium);
       position:absolute;
       height:300px;
       width:300px;
       display:block;
       opacity: 0.20;
       left:0;
       right:0;
       margin:auto;
       top:20px;
    }
    &.vip-premier {
       background:var(--bg-vip-premier);
       position:relative;
       height:auto;
       width:auto;
       display:block;
       overflow:hidden; 
    }
    &.vip-ultra {
       background:var(--bg-vip-ultra);
       position:relative;
       height:auto;
       width:auto;
       display:block;
       overflow:hidden; 
    }
    &.vip-premium-one {
       background:var(--bg-vip-ultra-one);
       position:absolute;
       height:300px;
       width:300px;
       display:block;
       opacity: 0.20;
       left:0;
    }
    &.vip-premium-two {
       background:var(--bg-vip-ultra-two);
       position:absolute;
       height:300px;
       width:300px;
       display:block;
       opacity: 0.20;
       right:0;
       left:auto;
    }
    &.a-list {
       border:2px solid;
       border-image:var(--bg-vip-a-list);
       position:relative;
       height:auto;
       width:auto;
       display:block;
       overflow:hidden;
       -webkit-border-radius: 4px;
    }
    &.bg-points {
       background:var(--bg-vip-ultra);
       position:static;
       height:auto;
       width:auto;
       display:block;
    }
    &.customize_first {
       background:var(--bg-customise-first);
       position:static;
       height:auto;
       width:auto;
       display:block;
    }
    &.email_block {
       background:var(--bg-vip-ultra);
       position:static;
       height:auto;
       width:auto;
       display:block;
       cursor:pointer;
    }
    &.email_block_active {
       background:var(--bg-customize-act);
       position:static;
       height:auto;
       width:auto;
       display:block;
       cursor:pointer;
    }
    &.list_icon_custom {
       background:var(--bg-perks);
       position:static;
       height:48px;
       width:48px;
       border-radius:50%;
       display:flex;
    }
    &.inner_popup_bg {
       background:var(--bg-confirm-pop);
       position:static;
       height:auto;
       width:auto;
       display:block;
       border:1px solid var(--border-customize-color);
    }
    &.inner_popup_transparent {
       background:var(--transparent);
       position:static;
       height:auto;
       width:auto;
       display:block;
       border:1px solid var(--transparent);
    }
    &.popup_confrim {
       position:absolute; 
      //  display:none;
       background:var(--transparent);
    }
    &.popup_confrim_news {
       position:fixed; 
      //  display:none;
       background:var(--transparent);
    }
    &.confirm_inners_pop {
       position:absolute; 
       display:block;
       background:var(--bg-confirm-popful);
    }
    &.confirm_inners_pop_one {
       position:absolute; 
       display:block;
       background:var(--bg-gray-color);
    }
    &.static_box_in_enable {
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        height:108px;
        background-color:var(--bg-vip-btn);
    }
    &.static_box_in {
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        height:108px;
        background-color:var(--bg-vip-ultra);
    }
    &.new_points {
        position:relative;
        display:inline-block;
        width:auto;
        height:auto;
        color:#fff;
        border-radius: 999px;
        background-color:var(--bg-vip-sec);
        border:1px solid var(--border-updates-color);
        font-size:14px;
        font-family:var(--font-Regular);
    }
    &.bar_invite {
        display:flex;
        width:auto;
        height:auto;
        position:relative;
        background-color:var(--bg-bar-color);
        justify-content:space-between;
    }
    &.darkin {
        background-color:var(--events-bg-color);
        position:relative;
        display:flex;
        flex-direction:column;
        border:1px solid var(--border-events-color);
    }
    &.lightin {
        background-color:var(--bg-bottom-events);
        position:relative;
        padding:10px 0;
    }
    &.articles_bg_in {
        position:absolute;
        width:100%;
        height:100%;
        border-radius:4px;
        background:var(--bg-articles-act);
    }
    &.bottom_in_update {
        position:relative;
        display:flex;
        justify-content:center;
        align-items:center;
        width:40px;
        height:40px;
        margin:0 auto;
    }
    &.bottom_in_update_active {
        position:relative;
        display:flex;
        justify-content:center;
        align-items:center;
        background-color:var(--bg-perks);
        border-radius:50%;
        width:40px;
        height:40px;
        margin:0 auto;
    }
    &.seem_works {
        position:relative;
        background-color:var(--articlelarge-bg-color);
        display:flex;
        justify-content:space-between;
         &::after {
            content: " ";
            display: block;
            width: 94%;
            height: 1px;
            background: #fff;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 15px;
            opacity: 0.20;
         }
    }
    &.profile_bg_filler {
        width:auto;
        height:auto;
        position:relative;
        background-color:var(--transparent);
    }
    &.profile_bg_fill {
        position:absolute;
        background-color:var(--articlelarge-bg-color);
        opacity:0.20;
    }
    &.profile_bg_list {
        position:relative;
        background-color:var(--bg-vip-ultra);
    }
    &.bottom_all_menu {
        position:relative;
        margin:10px 0 0;
    }
    &.qr_code_bg {
        background-color:var(--bg-input-field);
        position:relative;
    }
    &.gen_bg {
        background-color:var(--bg-genres-events);
        position:relative;
        display:inline-block;
        width:auto;
        height:auto;
    }
    &.dashbashes {
        background-color:var(--bg-bash-events);
        position:relative;
        display:inline-block;
        width:auto;
        height:auto;
        padding:3px 4px;
        border-radius:4px;
    }
`
