/* eslint-disable import/no-anonymous-default-export */

import axios from "axios"
import { CREATE_QRCODE, CREATE_USER, GET_EMAIL_EXISTS_USER, GET_USER, SET_ONBOARDING_ARTIST, SET_ONBOARDING_DOB, SET_ONBOARDING_GENRES, SET_ONBOARDING_LOCATION, SET_ONBOARDING_PUBLIC_PROFILE } from "./api"
import DAUserModel from "./models"
import { API_BASEURL } from "./settings"
import { getUserData } from "./authUtils"

// API call to get category list
const signupApi = (params) => {
   const url = `${API_BASEURL + CREATE_USER + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
            },
         })
         .then((response) => {
            // console.log(response);
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            const parsedResult = DAUserModel.parseErrorDefault(e.response)
            resolve(parsedResult)
         })
   })
}

const loginApi = (params) => {
   const url = `${API_BASEURL + GET_USER + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
            },
         })
         .then((response) => {
            // console.log(response);
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            // console.log("test", e);
            const parsedResult = DAUserModel.parseErrorDefault(e.response)
            resolve(parsedResult)
         })
   })
}

const generateQrCodeApi = (params) => {
   const token = getUserData();
   const url = `${API_BASEURL + CREATE_QRCODE + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `${"Bearer " + token}`,
            },
            responseType: "arraybuffer"
         })
         .then((response) => {
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            console.log("test", e);
         })
   })
}

const setPublicProfileApi = (params) => {
   const token = getUserData();
   const url = `${API_BASEURL + SET_ONBOARDING_PUBLIC_PROFILE + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `${"Bearer " + token}`,
            },
         })
         .then((response) => {
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            console.log("test", e);
         })
   })
}

const setBirthdateApi = (params) => {
   const token = getUserData();
   // console.log(token);
   const url = `${API_BASEURL + SET_ONBOARDING_DOB + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `${"Bearer " + token}`,
            },
         })
         .then((response) => {
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            console.log("test", e);
         })
   })
}

const setLocationApi = (params) => {
   const token = getUserData();
   const url = `${API_BASEURL + SET_ONBOARDING_LOCATION + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `${"Bearer " + token}`,
            },
         })
         .then((response) => {
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            console.log("test", e);
         })
   })
}

const setFavGenresApi = (params) => {
   const token = getUserData();
   const url = `${API_BASEURL + SET_ONBOARDING_GENRES + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `${"Bearer " + token}`,
            },
         })
         .then((response) => {
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            console.log("test", e);
         })
   })
}

const setFavArtistsApi = (params) => {
   const token = getUserData();
   const url = `${API_BASEURL + SET_ONBOARDING_ARTIST + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
               "Authorization": `${"Bearer " + token}`,
            },
         })
         .then((response) => {
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            console.log("test", e);
         })
   })
}


const getEmailExistsApi = (params) => {
   const url = `${API_BASEURL + GET_EMAIL_EXISTS_USER + "/"}`
   return new Promise((resolve) => {
      axios
         .post(`${url}`, params, {
            headers: {
               "Content-Type": "application/json",
            },
         })
         .then((response) => {
            // console.log(response);
            const parsedResult = DAUserModel.parseDefault(response)
            resolve(parsedResult)
         })
         .catch((e) => {
            // console.log("test", e);
            const parsedResult = DAUserModel.parseErrorDefault(e.response)
            resolve(parsedResult)
         })
   })
}

export default {
   signupApi,
   loginApi,
   generateQrCodeApi,
   setPublicProfileApi,
   setBirthdateApi,
   setLocationApi,
   setFavGenresApi,
   setFavArtistsApi,
   getEmailExistsApi,
}