import styled from 'styled-components';


export const LinkText = styled.a`
    font-size:12px;
    cursor:pointer;
    color:${({red,green,gray,white}) => (red  === 'true' && 'var(--red-color)') || (green === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (white  === 'true' && 'var(--white-color)')};
    &.whitepoint {
       font-size:14px;
    }
    &.bottom_menu {
        position:relative;
        &::after {
          content:" ";
          display:block;
          width:1px;
          height:55px;
          right: 0;
          top: 0;
          background-color:var(--white-color);
          position:absolute;
          opacity:0.10;
        }
    }
    &.gen_pd {
        padding:10px;
        display:inline-block;
        font-size:16px;
    }
`
export const Ul = styled.ul`
  padding:0;
  margin:0;
`
export const Li = styled.li`
 font-size:12px;
 color:var(--white-color);
 display:block;
 &.dash_news {
    font-size:14px;
 }
 &.generes_inline {
    display:inline-block;
 }
`
export const Paragraph = styled.p`
    color:${({red,green,gray,white,lightwhite,textGray}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (lightwhite  === 'true' && 'var(--light-white-color)') || (white  === 'true' && 'var(--white-color)') || (textGray  === 'true' && 'var(--opacity-gray-color)')};
    padding-bottom:0;
    margin-bottom:0;
    white-space: break-spaces;
    font-size:12px;
    font-family: 'Geist-Regular';
    &.size-14{font-size:14px;}
    &.points {
      font-family:var(--font-SemiBold);
    }
    &.subpoints {
      color:var(--light-white-color);
      font-size:13px;
    }
    &.point_txt {
      color:var(--point-gray-color);
      line-height:18px;
    }
    &.proceed_sub {
      font-size:14px;
    }
    &.location_desc {
      max-width:280px;
      margin:15px auto 0;
    }
    &.dash_bash {
        padding-bottom:30px;
    }
    &.s-13 {
        font-size:13px;
        max-width:270px;
    }
    &.qr_para {
        font-size:14px;
        font-weight:var(--font-Weight-Regular);
        max-width:300px;
        margin:0 auto;
        line-height:20px;
    }
    &.notify_txt {
        font-size:14px;
        font-weight:400;
        max-width:310px;
    }
    &.dash_total_points {
       font-size:14px; 
    }
    &.divide_points {
        font-size:14px;
    }
    &.points_in_claim {
        border:1px solid var(--border-customize-color);
        padding:10px 20px;
        margin:0 0 20px;
    }
    &.humans {
        font-size:14px;
        font-weight:var(--font-Weight-Regular);
        max-width:330px;
        margin:0 auto;
        line-height:20px;
    }
 `


 export const Span = styled.span`
    color:${({red,green,gray,white,lightwhite,textGray}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (lightwhite  === 'true' && 'var(--light-white-color)') || (white  === 'true' && 'var(--white-color)') || (textGray  === 'true' && 'var(--opacity-gray-color)')};
    font-size:12px;
    letter-spacing:1px;
    &.circle_profile_in {
        width:36px;
        height:36px;
        display:flex;
        justify-content:center;
        align-items:center;
        background-color:var(--bg-perks);
        border-radius:50%;
        margin:0 10px 0 0;
    }
    &.sliders {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color:var(--articlelarge-bg-color);
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 34px;
        &::before {
          position: absolute;
          content: "";
          height: 20px;
          width: 20px;
          left: 2px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
          border-radius: 50%;
        }
    }
    &.collects {
        font-size:16px;
    }
`

export const H6 = styled.h6` 
font-size:14px;
color:${({red,green,gray,white,lightwhite}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (lightwhite  === 'true' && 'var(--light-white-color)') || (white  === 'true' && 'var(--white-color)')};
`
export const H5 = styled.h5` 
font-size:16px;
color:${({red,green,gray,white,lightwhite}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (lightwhite  === 'true' && 'var(--light-white-color)') || (white  === 'true' && 'var(--white-color)')};
&.list_h5 {
    font-family:var(--font-Medium);
}
`
export const H4 = styled.h4` 
font-size:18px;
color:${({red,green,gray,white}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (white  === 'true' && 'var(--white-color)')};
`
export const H3 = styled.h3` 
font-size:20px;
color:${({red,green,gray,white}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (white  === 'true' && 'var(--white-color)')};
`
export const H2 = styled.h2` 
font-size:22px;
color:${({red,green,gray,white,lightwhite}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (lightwhite  === 'true' && 'var(--light-white-color)') || (white  === 'true' && 'var(--white-color)')};
&.vip_size {
    font-size:28px;
}
&.proceed_title {
    max-width:220px;
    margin:0 auto;
    font-family:var(--font-SemiBold);
    opacity:0.94;
}
&.proceed_titleall {
    max-width:270px;
    margin:0 auto;
    font-family:var(--font-SemiBold);
    opacity:0.94;
}
&.proceed_title_generes {
    max-width:350px;
    margin:0 auto;
    font-family:var(--font-SemiBold);
    opacity:0.94;
}
&.red_bottom {
    margin:20px 0;
}
`
export const H1 = styled.h1`
 font-size:24px;
 color:${({red,green,gray,white}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (white  === 'true' && 'var(--white-color)')};
 &.vip_size {
    font-size:28px;
 }
 `

export const Label = styled.label`
  display: block;
  font-size:11px;
  color:${({red,green,gray,white,lightwhite}) => (red  === 'true' && 'var(--red-color)') || (green  === 'true' && 'var(--green-color)') || (gray  === 'true' && 'var(--gray-color)') || (lightwhite  === 'true' && 'var(--light-white-color)') || (white  === 'true' && 'var(--white-color)')};
  opacity:0.70;
  &.location_txt {
    font-size:15px;
  }
  &.location_txt_lg {
    font-size:18px;
    color:#fff;
    opacity:1;
  }
  &.account_label {
    font-size:16px;
    opacity:0.70;
  }
  &.switch {
     position: relative;
     display: inline-block;
     width: 44px;
     height: 24px;
     opacity:1;
     vertical-align: top;
  }
`