import React, { useEffect, useState } from "react";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import { H2, Paragraph } from "../components/Text";
import InnerHeader from "../components/InnerHeader";
import { getUserData } from "../utils/authUtils";
import userApi from "../utils/userApi";
import { Buffer } from 'buffer';
import QR from '../assets/images/largeqrcode.svg';

function Qrcode() {
    const [image, setImage] = useState("")

    useEffect(() => {
        const data = getUserData()
        console.log(data);
        const params = {
            userID: data
        }
        userApi.generateQrCodeApi(params).then((res) => {
            // console.log(res);
            if (res.status === true) {
                // console.log(userID);
                const img = res.message
                let base64ImageString = Buffer.from(img, 'binary').toString('base64')
                let srcValue = `data:image/png;base64,${base64ImageString}`
                setImage(srcValue);
            }
        });
    }, [image])

    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25 relative">
           <InnerHeader PageTitle={"Your QR Code"} helpIcon={false} helpLink={"/public-profile"} />
            <Section className="text-center mt-20">
             <Background className="qr_code_bg pt-33 pb-33 max-244 m-auto displayblock radius-24"> 
              {image !== "" 
                ? <Image className="m-auto displayblock qrcodeImg" src={image} />
                : <Image className= "m-auto displayblock qrcodeImg" src={QR} />
              }
             </Background> 
             <H2 lightwhite="true" className="mtb-auto max-320 opacity-94">Scan your QR Code at events to get access</H2>
             <Paragraph lightwhite="true" className="opacity-70 qr_para">Don't worry, we don't share any of your private info with other members</Paragraph>
            </Section>
           </Main>
          </SubContainer>
        </MainContainer>  
    );
    }

export default Qrcode;