import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {  MainContainer, SubContainer, Main, Section, Image, Input, BackgroundImg } from "../components/Styles";
import { Background } from "../components/Background";
import { H2, Label, Paragraph } from "../components/Text";
import GpsfixIcon from '../assets/images/gpsfix.svg';
import GpsIcon from '../assets/images/gps.svg';
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";
import userApi from "../utils/userApi";
import { getUserData } from "../utils/authUtils";

function Location() {
  const navigate = useNavigate();
  const [isConfirmed, setIsConfirmed] = useState(false); 
  const [allowPopup, setAllowPopup] = useState(false); 
  const [locationInfo, setLocationInfo] = useState(""); 
  const allowLocation = () => {
    setAllowPopup(false)
    setIsConfirmed(true)
    // setLocationInfo("Denver, CA")
    navigator.geolocation.getCurrentPosition(pos=>{
      const {latitude,longitude} = pos.coords;
      // console.log(latitude,longitude)
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url).then(res=>res.json()).then(data=> {
          // console.log(data);
          setLocationInfo(data.display_name);
        }
      )
    })
  }

  const submit = () => {
    const userID = getUserData();
    const params = {
      userID: userID,
      location: locationInfo,
    }
    userApi.setLocationApi(params).then((res) => {
        // console.log(res);
        if (res.status) {
            if (res.message === "Location set") {
                navigate("/customise-vault", { state : { completeStep: "location"}})
            }
        }
    });
  }

    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25">            
            
           <InnerHeader PageTitle={"Location"} helpIcon={true} helpLink={"/public-profile"} />

            <Section>
             <Section className="mb-20">
              <Section className="relative" onClick={() => setAllowPopup(true)}>
               <Image src={GpsfixIcon} className="absolute w-auto top-22 left-14" />
               <Input defaultValue={locationInfo} disabled={locationInfo !== "" ? true : false} className="inputBg mt-10 fontRegular full_inname" placeholder="Allow access to your location" />
              </Section>
              {isConfirmed === true && (
                <Section className="relative mt-20">
                <Label lightwhite="true" className="pt-5 location_txt">
                 <Input checked disabled className="inputcheck locationcheck absolute left-0 m-0" type="checkbox" /> <BackgroundImg className="checktick vertical-middle mr-5"></BackgroundImg> Share this data along with my bio</Label>
               </Section> 
              )}
               
             </Section>
            </Section>
            <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto">
             <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom" disabled={!isConfirmed} onClick={submit}>Confirm</DefaultButton>
             <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom" onClick={() => navigate(-1)}>Go Back</DefaultButton>
            </Section>
            {allowPopup === true && (
            <Background className="popup_confrim left-0 right-0 m-auto top-0 max-590">
             <Background className="confirm_inners_pop" />   
              <Section className="inner_popup_center displaytable h-full"> 
               <Section className="inner_popup_middle relative zindex displaytablecell vertical-middle pl-15 pr-15">
                <Background className="inner_popup_bg text-center pt-32 pb-20 pl-16 pr-16 radius-4">
                 <Image className="mb-15" src={GpsIcon} />
                 <H2 lightwhite="true" className="proceed_title">We need access to your location</H2>
                 <Paragraph lightwhite="true" className="opacity-70 proceed_sub location_desc">Don’t worry, we don’t share any of your private info with other members</Paragraph>
                 <Section className="displayflex mt-20">
                  <DefaultButton className="text-small-base fontSemiBold custom_bottom skipin mr-10" onClick={() => setAllowPopup(false)}>Later</DefaultButton>
                  <DefaultButton className="text-small-base fontSemiBold custom_bottom" onClick={() => allowLocation()}>Allow</DefaultButton>
                 </Section> 
                </Background>
               </Section>
              </Section>  
            </Background>
             )}
           </Main>
          </SubContainer>
        </MainContainer>
    );
    }

export default Location;