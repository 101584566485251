/* eslint-disable react/jsx-no-undef */

import React from "react";
import { Route, Navigate, Routes } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid'

import { StyleSheetManager } from "styled-components";

import CustomizeVault from './pages/customizeVault.js';
import Marketplace from './pages/marketplace.js';
import News from './pages/news.js';
import NewsDetail from './pages/newsDetail.js';
import Profile from './pages/profile.js';
import PublicProfile from './pages/publicProfile.js';
import Qrcode from './pages/qrcode.js';
import AccountDetails from './pages/accountDetails.js';
import Notification from './pages/notification.js';
import Dashbash from './pages/dashbash.js';
import Order from './pages/order.js';
import OrderQrcode from './pages/orderqrcode.js';
import OrderDashBash from './pages/orderDashbash.js';
import BirthDate from './pages/birthDate.js';
import Location from './pages/location.js';
import FavoriteGenres from './pages/favoriteGenres.js';
import FavoriteArtists from './pages/favoriteArtists.js';
import Updates from './pages/updates.js';
import Vault from './pages/vault.js';
import Vault1 from './pages/vault1.js';
import Onboarding from './pages/onboarding.js';
import Welcome from './pages/welcome.js';
import WelcomeOne from './pages/welcome-one.js'
import SignUp from "./pages/signup";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgotPassword";
import NewPassword from "./pages/newPassword";
import PointsHistory from "./pages/points_history.js"
import ClaimCollectible from './pages/claimCollectible.js';
import EventSigned from "./pages/eventSigned.js";
import Articles from "./pages/articles.js";
import Points from "./pages/points.js";
import Home from "./pages/home.js";
// import { getUserData } from "./utils/authUtils.js";

function CommonRoutes() {
    // const navigate = useNavigate();
    // const location = useLocation();
    // useEffect(() => {
    //     // console.log(location.pathname, "location");
    //     const path = location && location.pathname
    //     if ((path === "/login") || (path === "/forgot-password") || (path === "/new-password") || (path === "/signup") || (path === "/welcome") || (path === "/welcomeone")) {
    //         if (getUserData() !== null) {
    //             setTimeout(() => {
    //                 navigate("/onboarding")
    //             }, 100)
    //             return
    //         }
    //     } else if (path === "/") {
    //         if (getUserData() === null) {
    //             setTimeout(() => {
    //                 navigate("/")
    //             }, 100)
    //             return
    //         }
    //     } else {
    //         if (getUserData() === null) {
    //             setTimeout(() => {
    //                 navigate("/login")
    //             }, 100)
    //             return
    //         }
    //     }
    // }, [navigate,location]);
    // const data = (getUserData() === null ? true : false);
    return (
        <StyleSheetManager
            enableVendorPrefixes
            shouldForwardProp={(propName, elementToBeRendered) => {
                return typeof elementToBeRendered === 'string' ? isPropValid(propName) : true;
            }}
        >
            {/* <Router> */}
            <Routes>

                <Route path="*" element={<Navigate to="/welcome" />} />

                {/* Info Onboarding first screen, has buttons for signup and login page */}
                {/* <Route path="/" element={data ? <Welcome /> : <Onboarding />} /> */}
                <Route path="/" element={<Welcome />} />
                <Route path="/welcome" element={<Welcome />} />
                <Route path="/welcomeone" element={<WelcomeOne />} />

                {/* Screen 2 */}
                <Route path="/signup" element={<SignUp />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/new-password" element={<NewPassword />} />

                <Route path="/home" element={<Home />} />

                {/* Screen 3 After user has signedup or logged in, they will be redirected to the onboarding page. The points could be refactored into a popup on the onboarding page. */}
                {/* <Route path="/onboarding" element={<Onboarding />} /> */}
                <Route path="/onboarding" element={
                    <Onboarding />
                } />

                <Route path="/points" element={<Points />} />


                {/* Screen 4 Im thinking of having publicprofile, birthdate, location, favorite genres and favorite artists all be popups on the customize-vault page */}
                <Route path="/customise-vault" element={<CustomizeVault />} />
                {/* Customize vault - inner screens */}
                <Route path="/public-profile" element={<PublicProfile />} />
                <Route path="/birth-date" element={<BirthDate />} />
                <Route path="/location" element={<Location />} />
                <Route path="/favorite-genres" element={<FavoriteGenres />} />
                <Route path="/favorite-artists" element={<FavoriteArtists />} />

                {/* Screen 5 After the vault has been customized, the user will be redirected to the news page */}
                {/* Updates and Vault */}
                <Route path="/updates" element={<Updates />} />
                <Route path="/vault" element={<Vault />} />
                <Route path="/vault1" element={<Vault1 />} />
                <Route path="/points-history" element={<PointsHistory />} />

                <Route path="/news" element={<News />} />
                <Route path="/news-detail" element={<NewsDetail />} />
                <Route path="/articles" element={<Articles />} />

                {/* There will be the event sign in overlay on the bottom of the news, here we will make a new page for the challenges. */}
                <Route path="/dashbash" element={<Dashbash />} />
                <Route path="/order" element={<Order />} />
                <Route path="/order-qrcode" element={<OrderQrcode />} />
                <Route path="/order-dashbash" element={<OrderDashBash />} />
                <Route path="/order-claimCollectible" element={<ClaimCollectible />} />
                <Route path="/order-signedin" element={<EventSigned />} />

                {/* Need to make a coming soon conmponent for this page */}
                <Route path="/market" element={<Marketplace />} />

                {/* Profile and inner screens */}
                <Route path="/profile" element={<Profile />} />
                <Route path="/qr-code" element={<Qrcode />} />
                <Route path="/account-details" element={<AccountDetails />} />
                <Route path="/notification" element={<Notification />} />

            </Routes>
            {/* </Router> */}
        </StyleSheetManager>
    );
}

export default CommonRoutes;
