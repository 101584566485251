import React from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import NewsLargeImg from '../assets/images/news_large.svg';
import CheckLargerIcon from '../assets/images/check_larger.svg';
import { H2, H4, Paragraph } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import Header from "../components/Header";

function NewsDetail() { 
    return (
        <MainContainer>
         <Background/>
         <SubContainer className="pt-80">
          <Header backIcon={true} settingIcon={true} />

           <Main className="padding-25 relative">
            <Section>
             <Image className="w-full outlinenone boxshadownone" src={NewsLargeImg} />
            </Section>
            <Section className="pt-15 pb-15 news-auto mb-150">
             <H4 white="true" className="fontSemiBold mt-0 mb-10 max-250">Bon Jovi announces a new artifact collection</H4>
             <Paragraph white="true" className="opacity-60 lineheight-18 mt-0">Located at Hauz Khas in Delhi, the Bar/Pub Imperfecto is easily  accessible to all your guests. In business since 2010 Imperfecto is one  of the most popular event venues in city. Imperfecto has been a prime  location to celebrate Birthday Party, Bachelor Party, Social Gathering  and many other events. Imperfecto can accommodate between 10 - 50 guests  at a time. Imperfecto provides numerous facilities like Power Backup,  Air Condition, DJ and many more which ensures that your event is  successful.</Paragraph>
            </Section>
            <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto"> 
             <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom skipin">Go Back</DefaultButton>
            </Section>
            <Background className="popup_confrim_news left-0 right-0 m-auto top-0 max-590">
             <Background className="confirm_inners_pop_one" />   
              <Section className="inner_popup_center displaytable h-full"> 
               <Section className="inner_popup_middle relative zindex displaytablecell vertical-middle pl-15 pr-15">
                <Background className="inner_popup_transparent text-center">
                 <Image className="mb-15" src={CheckLargerIcon} />
                 <H2 lightwhite="true" className="proceed_titleall">You’ve successfully <span className="displayblock">registered for</span> ‘Dash Bash - New York’</H2>
                 <Paragraph lightwhite="true" className="opacity-70 proceed_sub location_desc s-13 fontRegular font-Regular">50 points have been successfully been awarded to you</Paragraph>
                 <Section className="displayflex mt-20 absolute left-0 bottom-20"> 
                  <Section className="pl-15 pr-15">
                   <DefaultButton className="text-small-base fontSemiBold custom_bottom">Awesome</DefaultButton>
                  </Section>
                 </Section> 
                </Background>
               </Section>
              </Section>  
            </Background>   
          </Main>
         </SubContainer>
        </MainContainer>   
    );
    }

export default NewsDetail;