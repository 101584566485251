import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Section, Image } from "../components/Styles";
import { H5 } from "../components/Text";
import BackIcon from '../assets/images/back.svg';
import OnboardingHelponeIcon from '../assets/images/onboarding_help_one.svg';


function InnerHeader(props) {  
    const navigate = useNavigate();
    return (
        <Section className="text-center relative mb-10">
            <Link className="absolute top-12 left-0" onClick={() => navigate(-1)}>
                <Image src={BackIcon} />
            </Link>   
            
            <H5 white="true" className="mt-15">{props.PageTitle}</H5>
            
            {props.helpIcon === true && (
            <Link className="absolute top-12 right-0"  to={props.helpLink}>
                <Image src={OnboardingHelponeIcon} />
            </Link>
                   )}
       </Section>   
    );
    }

export default InnerHeader;