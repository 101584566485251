/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import OnboardingStarIcon from '../assets/images/onboarding_star.svg';
import EmailIcon from '../assets/images/email_icon.svg';
import PublicProfileIcon from '../assets/images/public_profile.svg';
import BirthDateIcon from '../assets/images/birthdate.svg';
import LocationIcon from '../assets/images/location.svg';
import GenresIcon from '../assets/images/genres.svg';
import ArtistsIcon from '../assets/images/artists.svg';
import CaretRightIcon from '../assets/images/caretright.svg';
import GpsIcon from '../assets/images/gps.svg';
import { H2, H5, Paragraph, LinkText } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";

function CustomizeVault() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [isConfirmed, setIsConfirmed] = useState(false); 
  const [percent, setPercent] = useState(50); 
  const [completedCount, setCompletedCount] = React.useState(1);
  const [completedStatus, setCompletedStatus] = React.useState({
    email: true,
    public_profile: false,
    birthdate: false,
    location: false,
    favorite_genres: false,
    favorite_artists: false
  });

  const totalCount = Object.keys(completedStatus).length


  useEffect(() => {   
    initStatus()     
  }, [])


  useEffect(() => {       
    const per = ((completedCount) / totalCount) * 100;
    setPercent(per);
  }, [completedCount])

  const initStatus = () => {
    if(state && state.completeStep !== "") {
      setCompletedStatus({
        ...completedStatus,
        [state.completeStep]: true
      })     
      setCompletedCount(2)
      // setTimeout(() => {
      //   const completedObj = Object.keys(completedStatus).filter(i => completedStatus[i] === true)
      //   setCompletedCount(Object.keys(completedObj).length)
      // }, 200)   
    } 
  }

  const confirmProceed = (status) => {
    setIsConfirmed(status)
  }

    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25 relative">

           <InnerHeader PageTitle={"Customize Vault"} helpIcon={true} helpLink={"/customize"} />            

            <Background className="customize_first pl-15 pr-15 pt-13 pb-13 radius-4">
             <Section className="displayflex content-between">
              <Paragraph white="true" className="mt-0 opacity-50">Finished {completedCount}/{totalCount}</Paragraph>
              <Paragraph white="true" className="mt-0 opacity-50">10 points earned</Paragraph>
             </Section>
             <Section className="displayblock pt-6">
              <progress className="w-full h-2 displayblock" max="100" value={percent}></progress> 
             </Section>
            </Background>
            <Section className="mb-60">
             <ul className="p-0 mb-0">
              <li className="block mb-10">
               <Background className={completedStatus.email === true? "email_block_active padding-16 radius-4": "email_block padding-16 radius-4"}>
                 <Section className="displayflex relative">
                  <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={EmailIcon} /></Background></Section>
                  <Section className="w-auto pl-10">
                   <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Email</H5><Paragraph white="true" className="opacity-50 mt-5"><Image className="mr-5" src={OnboardingStarIcon} />
                   <span className={completedStatus.email === true?"text-line" :""}>10 points</span> 
                   {completedStatus.email === true? " Done!" :""}</Paragraph>
                  </Section>
                  <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                 </Section> 
               </Background>
              </li>
              <li className="block mb-10">
               <Background className={completedStatus.public_profile === true? "email_block_active padding-16 radius-4": "email_block padding-16 radius-4"} onClick={() => navigate("/public-profile")}>
                 <Section className="displayflex relative">
                  <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={PublicProfileIcon} /></Background></Section>
                  <Section className="w-auto pl-10">
                   <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Public Profile</H5><Paragraph white="true" className="opacity-50 mt-5"><Image className="mr-5" src={OnboardingStarIcon} />
                   <span className={completedStatus.public_profile === true?"text-line" :""}>10 points</span> 
                   {completedStatus.public_profile === true? " Done!" :""}
                   </Paragraph>
                  </Section>
                  <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                 </Section> 
               </Background>
              </li>
              <li className="block mb-10">
               <Background className={completedStatus.birthdate === true? "email_block_active padding-16 radius-4": "email_block padding-16 radius-4"} onClick={() => navigate("/birth-date")}>
                 <Section className="displayflex relative">
                  <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={BirthDateIcon} /></Background></Section>
                  <Section className="w-auto pl-10">
                   <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Birthday</H5><Paragraph white="true" className="opacity-50 mt-5"><Image className="mr-5" src={OnboardingStarIcon} />
                   <span className={completedStatus.birthdate === true?"text-line" :""}>10 points</span> 
                   {completedStatus.birthdate === true? " Done!" :""}
                   </Paragraph>
                  </Section>
                  <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                 </Section> 
               </Background>
              </li>
              <li className="block mb-10">
               <Background className={completedStatus.location === true? "email_block_active padding-16 radius-4": "email_block padding-16 radius-4"} onClick={() => navigate("/location")}>
                 <Section className="displayflex relative">
                  <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={LocationIcon} /></Background></Section>
                  <Section className="w-auto pl-10">
                   <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Location</H5><Paragraph white="true" className="opacity-50 mt-5"><Image className="mr-5" src={OnboardingStarIcon} />
                   <span className={completedStatus.location === true?"text-line" :""}>10 points</span> 
                   {completedStatus.location === true? " Done!" :""}
                   </Paragraph>
                  </Section>
                  <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                 </Section> 
               </Background>
              </li>
              <li className="block mb-10">
               <Background className={completedStatus.favorite_genres === true? "email_block_active padding-16 radius-4": "email_block padding-16 radius-4"} onClick={() => navigate("/favorite-genres", { state: {
                type: "customise-vault"
               }})}>
                 <Section className="displayflex relative">
                  <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={GenresIcon} /></Background></Section>
                  <Section className="w-auto pl-10">
                   <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Favorite Genres</H5><Paragraph white="true" className="opacity-50 mt-5"><Image className="mr-5" src={OnboardingStarIcon} />
                   <span className={completedStatus.favorite_genres === true?"text-line" :""}>50 points</span> 
                   {completedStatus.favorite_genres === true? " Done!" :""}
                   </Paragraph>
                  </Section>
                  <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                 </Section> 
               </Background>
              </li>
              <li className="block mb-60">
               <Background className={completedStatus.favorite_artists === true? "email_block_active padding-16 radius-4": "email_block padding-16 radius-4"} onClick={() => navigate("/favorite-artists", { state: {
                type: "customise-vault"
               }})} >
                 <Section className="displayflex relative">
                  <Section className="w-auto"><Background className="list_icon_custom displayflex content-center items-center"><Image src={ArtistsIcon} /></Background></Section>
                  <Section className="w-auto pl-10">
                   <H5 white="true" className="mt-0 mb-0 list_h5 fontMedium">Favorite Artists</H5><Paragraph white="true" className="opacity-50 mt-5"><Image className="mr-5" src={OnboardingStarIcon} />
                   <span className={completedStatus.favorite_genres === true?"text-line" :""}>50 points</span> 
                   {completedStatus.favorite_genres === true? " Done!" :""}
                   </Paragraph>
                  </Section>
                  <LinkText className="absolute right-0 top-5"><Image src={CaretRightIcon} /></LinkText>
                 </Section> 
               </Background>
              </li>
             </ul>   
            </Section> 
            <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto"> 
            <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom" onClick={() => confirmProceed(true)}>Go to my Vault</DefaultButton>
            </Section>
            {isConfirmed === true && (
                <Background className="popup_confrim left-0 right-0 m-auto top-0 max-590">
                  <Background className="confirm_inners_pop" />   
                  <Section className="inner_popup_center displaytable h-full"> 
                    <Section className="inner_popup_middle relative zindex displaytablecell vertical-middle pl-15 pr-15">
                    <Background className="inner_popup_bg text-center pt-32 pb-20 pl-16 pr-16 radius-4">
                      <Image className="mb-15" src={GpsIcon} />
                      <H2 lightwhite="true" className="proceed_title">Are you sure you want to proceed?</H2>
                      <Section className="displayflex mt-20">
                      <DefaultButton className="text-small-base fontSemiBold custom_bottom skipin cancel_wd mr-10" onClick={() => confirmProceed(false)}>Cancel</DefaultButton>
                      <DefaultButton className="text-small-base fontSemiBold custom_bottom" onClick={() => navigate("/updates")} >Yes, let's proceed</DefaultButton>
                      </Section> 
                    </Background>
                    </Section>
                  </Section>  
              </Background>
            )}
          
           </Main>
          </SubContainer>
        </MainContainer>
    );
    }

export default CustomizeVault;