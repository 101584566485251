import React from "react";
import { Main, Image } from "../components/Styles";
import { Link, useNavigate } from "react-router-dom";
import BackIcon from '../assets/images/back.svg';
import DefinitiveLogoIcon from '../assets/images/definitive_logo.svg';
// import OnboardingHelponeIcon from '../assets/images/onboarding_help_one.svg';
// import Profile from '../assets/images/header-profile.svg';
// import Settings from '../assets/images/settings.svg';
import StarGray from '../assets/images/star-gray.svg'
import { Paragraph } from "./Text";
import { DefaultButton } from "./DefaultButton";



function Header(props) {
    const navigate = useNavigate();

    React.useEffect(() => {
        const header = document.querySelector(".page-header");
        const toggleClass = "is-sticky";

        const handleScroll = () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll > 20) {
                header.classList.add(toggleClass);
            } else {
                header.classList.remove(toggleClass);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <Main className="page-header">
            <Main className="pr-15 pl-15 pb-15 pt-15">
                <Main className="text-center relative flex space-evenly items-center">
                    <Main className="w-33">

                        {props.backIcon === true && (
                            <Link className="absolute top-12 left-0" onClick={() => navigate(-1)}>
                                <Image src={BackIcon} />
                            </Link>
                        )}


                        {props.EventPoints === true && (
                            <DefaultButton className="EventPoints w-auto absolute top-12 left-0 flex content-center items-center" onClick={() => navigate(-1)}>
                                <Image src={StarGray} className="mr-5" /> 2000 Points
                            </DefaultButton>
                        )}

                        {props.vault === true && (
                            <Main className="text-left">
                                <Paragraph gray="true" className="pt-0 mt-0">You</Paragraph>
                                <Paragraph white="true" className="pt-0 mt-0">Your QR Code</Paragraph>
                                {/* <Paragraph white="true" className="pt-0 mt-0">{props.tier ? props.tier : "VIP"}</Paragraph> */}
                            </Main>
                        )}

                    </Main>

                    <Main className="w-33">
                        <Link to="/updates" className="p-10">
                            <Image src={DefinitiveLogoIcon} />
                        </Link>
                    </Main>

                    {/* <Main className="w-33 flex content-end">

                        {props.helpIcon === true && (
                            <Link to={props.helpLink} className="absolute top-12 right-0">
                                <Image src={OnboardingHelponeIcon} />
                            </Link>
                        )}

                        {props.profileIcon === true && (
                            <Link to={props.profileLink} className="pr-10 pl-10">
                                <Image src={Profile} />
                            </Link>
                        )}

                        {props.settingIcon === true && (
                            <Link to={props.settingLink} className="pr-10 pl-10" >
                                <Image src={Settings} />
                            </Link>
                        )}

                    </Main> */}
                </Main>
            </Main>
        </Main>
    );
}

export default Header;