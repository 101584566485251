import React from "react";
import { useNavigate } from "react-router-dom";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import Footer from "../components/Footer";
import ProfileImg from '../assets/images/profile_img.png';
import CaretRightIcon from '../assets/images/caretright.svg';
import ProfileQrcodeSmalltIcon from '../assets/images/profile_qrcode_small.svg';
import ProfileVaultIcon from '../assets/images/profilevault.svg';
import ProfileCircleIcon from '../assets/images/profile_circle.svg';
import ProfileNotificationIcon from '../assets/images/profile_notification.svg';
// import AppleLogoIcon from '../assets/images/applelogo.svg';
import SignOutIcon from '../assets/images/signout.svg';
import { H2, H5, LinkText, Ul, Li, Span } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";
import { clearUserData } from "../utils/authUtils";
import UserCircleIcon from '../assets/images/usercircle.svg';


function Profile() {  
  const navigate = useNavigate();
  const handleClick = () => {
    clearUserData();
    navigate("/welcome");
  }
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="relative">
            <Background className="profile_bg_filler padding-25">
             <Background className="profile_bg_fill" /> 
              <Section className="relative zindex">  
              
              <InnerHeader PageTitle={"Profile"} helpIcon={false} helpLink={"/welcome"} />

               <Section className="text-center mt-10">
                <Image className="profile_img" src={UserCircleIcon} />
                <H2 white="true">You</H2>
                <DefaultButton className="mb-10 text-small-base fontSemiBold premium_btn">VIP</DefaultButton>
               </Section>
              </Section>
            </Background>
            <section className="p-15 mb-100">
             <Ul>
              <Li className="mb-10">
               <Background className="profile_bg_list radius-4"> 
                <LinkText onClick={() => navigate("/qr-code")} className="displayflex content-between items-center pt-13 pb-13 pl-16 pr-16">
                 <section className="displayflex items-center">
                  <Span className="circle_profile_in"><Image src={ProfileQrcodeSmalltIcon} /></Span>
                  <H5 className="mt-0 mb-0">Your QR Code</H5>
                 </section>
                 <section>
                  <Image src={CaretRightIcon} />
                 </section>
                </LinkText>
               </Background>
              </Li>
              {/* <Li className="mb-10">
               <Background className="profile_bg_list radius-4"> 
                <LinkText onClick={() => navigate("/vault")} className="displayflex content-between items-center pt-13 pb-13 pl-16 pr-16">
                 <section className="displayflex items-center">
                  <Span className="circle_profile_in"><Image src={ProfileVaultIcon} /></Span>
                  <H5 className="mt-0 mb-0">Vault</H5>
                 </section>
                 <section>
                  <Image src={CaretRightIcon} />
                 </section>
                </LinkText>
               </Background>
              </Li> */}
              <Li className="mb-10">
               <Background className="profile_bg_list radius-4"> 
                <LinkText onClick={() => navigate("/account-details")} className="displayflex content-between items-center pt-13 pb-13 pl-16 pr-16">
                 <section className="displayflex items-center">
                  <Span className="circle_profile_in"><Image src={ProfileCircleIcon} /></Span>
                  <H5 className="mt-0 mb-0">Account Details</H5>
                 </section>
                 <section>
                  <Image src={CaretRightIcon} />
                 </section> 
                </LinkText>
               </Background>
              </Li>
              <Li className="mb-10">
               <Background className="profile_bg_list radius-4 pointer-none opacity-40"> 
                <LinkText onClick={() => navigate("/notification")} className="displayflex content-between items-center pt-13 pb-13 pl-16 pr-16">
                 <section className="displayflex items-center">
                  <Span className="circle_profile_in"><Image src={ProfileNotificationIcon} /></Span>
                  <H5 className="mt-0 mb-0">Notifications</H5>
                 </section>
                 <section>
                  <Image src={CaretRightIcon} />
                 </section>
                </LinkText>
               </Background>
              </Li>
             </Ul>
            </section>
            <Section>
             <Section className="pl-15 pr-15 pb-15 w-auto displayblock">   
              {/* <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom "><Image className="vertical-middle mr-10" src={AppleLogoIcon} /> Rate us on the App Store</DefaultButton> */}
              <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom mt-15" onClick={handleClick}><Image className="vertical-middle mr-10" src={SignOutIcon} /> Logout</DefaultButton>
             </Section> 
            </Section>
           </Main>

           <Footer />

          </SubContainer>
        </MainContainer>  
    );
    }

export default Profile;
