/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useNavigate } from "react-router-dom";
import {  MainContainer, SubContainer, Main, Section, Image, Input, Textarea } from "../components/Styles";
import { Background } from "../components/Background";
import { Label } from "../components/Text";
import UserCircleIcon from '../assets/images/usercircle.svg';
import OnboardingInfoIcon from '../assets/images/onboarding_info.svg';
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";
import userApi from "../utils/userApi";

function PublicProfile() {
  const navigate = useNavigate();
  const [isTextareaFocused, setIsTextareaFocused] = React.useState(false);
  const [formData, setFormData] = React.useState({
    username: "",
    bio: ""
});
const handleChange = (e) => {
  setFormData({
      ...formData,
      [e.target.name]: e.target.value
  });
};
const submit =()=>{
  const params = {
    name: formData.username,
  }
  userApi.setPublicProfileApi(params).then((res) => {
      // console.log(res);
      if (res.status) {
          if (res.message === "Name set") {
              navigate("/customise-vault")
          }
      }
  });  
}
const handleKeyDown = (e) => {
    if (e.key === "Enter" && formData.username && formData.bio && !isTextareaFocused) {
      submit();
    } else if (e.key === "Escape") {
      navigate(-1);
    }  
};

React.useEffect(() => {
  window.addEventListener("keydown", handleKeyDown);
  return () => {
    window.removeEventListener("keydown", handleKeyDown);
  };
}, [formData, isTextareaFocused]); // eslint-disable-line react-hooks/exhaustive-deps
const onblur = () => {
  setIsTextareaFocused(prevState => {
    return false;
  });
}
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25 relative">

           <InnerHeader PageTitle={"Public Profile"} helpIcon={true} helpLink={"/public-profile"} />

            <Section>
             <Section className="mb-20">
              <Label lightwhite="true" className="uppercase">Full Name<Image className="wd-12 ht-12 ml-5 vertical-middle opacity-50" src={OnboardingInfoIcon} /></Label>
              <Section className="relative">
               <Image src={UserCircleIcon} className="absolute w-auto top-22 left-14" />
               <Input autoComplete="off" name="username" className="inputBg mt-10 fontRegular full_inname" placeholder="Full Name" value={formData.username} onChange={handleChange}/>
              </Section>
             </Section>
             <Section className="mb-20">
              <Label lightwhite="true" className="uppercase">Bio<Image className="wd-12 ht-12 ml-5 vertical-middle opacity-50" src={OnboardingInfoIcon} /></Label>
               <Textarea autoComplete="off" name="bio" className="inputBg mt-10 fontRegular" placeholder="You like mangoes, and John Bon Jovi..." value={formData.bio} onChange={handleChange}  
                onFocus={() => setIsTextareaFocused(true)}
                onBlur={onblur} />
             </Section>
            </Section>
            <Section className="fixed w-auto bottom-20 left-0 right-0 pl-25 pr-25 max-590 m-auto">
             <DefaultButton className={`mb-10 text-small-base fontSemiBold custom_bottom ${!(formData.username && formData.bio) ? "disable" : ""}`} disabled={!(formData.bio && formData.username)} onClick={submit}>Confirm</DefaultButton>
             <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom" onClick={() => navigate(-1)}>Go Back</DefaultButton>
            </Section> 
           </Main>
          </SubContainer>
        </MainContainer>
    );
    }

export default PublicProfile;