import React from "react";
import styled from "styled-components";

const Text = styled.h1 `
    color: red;
    
    `;


function Marketplace() {  
    return (

        <>
            <Text>Hello from Marketplace</Text>
        </>
        
    );
    }

export default Marketplace;