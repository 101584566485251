import React from "react";
import { Link, useNavigate } from "react-router-dom";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import DefinitiveLogoIcon from '../assets/images/definitive_logo.svg';
import OnboardingHelponeIcon from '../assets/images/onboarding_help_one.svg';
import OnboardingStarIcon from '../assets/images/onboarding_star.svg';
import OnboardingNewspaperIcon from '../assets/images/onboarding_newspaper.svg';
import OnboardingTimerIcon from '../assets/images/onboarding_timer.svg';
import OnboardingInfoIcon from '../assets/images/onboarding_info.svg';
import OnboardingDiscountIcon from '../assets/images/onboarding_discount.svg';
import OnboardingChatIcon from '../assets/images/onboarding_chat.svg';
import OnboardingDiamondIcon from '../assets/images/onboarding_diamond.svg';
import OnboardingVideoIcon from '../assets/images/onboarding_video.svg';
import OnboardingStarDateIcon from '../assets/images/onboarding_star_date.svg';
import { H1, H2, H5, H6, Paragraph } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";

function Onboarding() {  
    const navigate = useNavigate();
    const goToPoints = () => {
        navigate("/points")
    }
    const goToVault = () =>{
        navigate("/vault")
    }
    const goToCustomiseVault = () => {
        navigate("/customise-vault")
    }
    return (

        <MainContainer>
         <Background/>
          <SubContainer >
           <Main className="padding-25">
            <Section className="text-center relative">
             <Image src={DefinitiveLogoIcon} />
             <Link className="absolute top-12 right-0" to="/onboarding"><Image src={OnboardingHelponeIcon} /></Link>
            </Section>
            <Section>
             <H6 white="true" className="uppercase fontSemiBold opacity-50 mt-10 mb-10">Your current tier is</H6>
            </Section>
            <Background className="vip-section-box pt-16 pb-24 pl-15 pr-15 radius-4">
             <H1 white="true" className="opacity-90 mt-0 mb-0 vip_size">VIP</H1>
             <Paragraph white="true" className="fontSemiBold opacity-50"><Image className="mr-5" src={OnboardingStarIcon} />+50 Points</Paragraph>
             <H5 white="true" className="opacity-50 fontLight font-Regular mb-10">Perks</H5>
             <ul className="p-0 mb-0 mt-20">
              <li className="block list fontLight mb-10">
               <Background className="perks-box pl-15 pr-15 pt-10 pb-10 opacity-50 radius-4">
                <span className="width-30 inline-block vertical-middle"><Image className="vertical-middle" src={OnboardingNewspaperIcon} /></span> Read-access to exclusive News
               </Background> 
              </li>
              <li className="block list fontLight mb-10">
               <Background className="perks-box pl-15 pr-15 pt-10 pb-10 opacity-50 radius-4"> 
                <span className="width-30 inline-block vertical-middle"><Image className="vertical-middle" src={OnboardingTimerIcon} /></span> Early access to event sales
               </Background> 
              </li>
              <li className="block list fontLight">
               <Background className="perks-box pl-15 pr-15 pt-10 pb-10 opacity-50 radius-4"> 
                <span className="inline-block vertical-middle width-30 0.50 text-base fontSemiBold">2X</span> 2X artifact point multiplier
               </Background>   
              </li>
             </ul>
            </Background>
            <Section className="mt-15 mb-20">
             <DefaultButton className="radius-999 earn-btn" onClick={() => goToPoints()} ><Image className="vertical-middle mr-3" src={OnboardingInfoIcon} /> How to earn more points?</DefaultButton>
            </Section>
            <Section>
             <H6 white="true" className="uppercase fontSemiBold opacity-50 mt-10 mb-25">Other tiers</H6>
            </Section>
            <Background className="vip-plus radius-4 pt-16 pb-16 pl-19 pr-19 mb-20">
             <Background className="vip-premium" />   
             <H2 white="true" className="opacity-90 mt-0 mb-0 vip_size">VIP Plus</H2>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 mt-5">Unlocks at <Image className="mr-5" src={OnboardingStarIcon} />500</Paragraph>
             </Section>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 text-base">Everything in VIP and...</Paragraph>
             </Section>
             <Section className="bottom-buttons displayflex relative overflow-x overflowhide">
              <DefaultButton className="vip_premium_btn mr-10 nowrap"><Image className="vertical-middle mr-10" src={OnboardingDiscountIcon} />10% artifact discount</DefaultButton>
              <DefaultButton className="vip_premium_btn nowrap"><Image className="vertical-middle mr-10" src={OnboardingTimerIcon} />Early access to P1 tickets</DefaultButton>
             </Section> 
            </Background>
            <Background className="vip-premier radius-4 pt-16 pb-16 pl-19 pr-19 mb-20">
             <Background className="vip-premium" />
             <H2 white="true" className="opacity-90 mt-0 mb-0 vip_size">Platinum</H2>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 mt-5">Unlocks at <Image className="mr-5" src={OnboardingStarIcon} />1000 points</Paragraph>
             </Section> 
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 text-base">Everything in VIP Plus and...</Paragraph>
             </Section> 
             <Section className="bottom-buttons displayflex relative overflow-x overflowhide">
              <DefaultButton className="vip_premium_btn mr-10 nowrap"><Image className="vertical-middle mr-10" src={OnboardingChatIcon} />Like + Comment access to News</DefaultButton>
              <DefaultButton className="vip_premium_btn nowrap"><Image className="vertical-middle mr-10" src={OnboardingDiamondIcon} />Limited-edition artifacts</DefaultButton>
             </Section>
            </Background>
            <Background className="vip-ultra radius-4 pt-16 pb-16 pl-19 pr-19 mb-20">
             <Background className="vip-premium-one" />
             <Background className="vip-premium-two" />
             <H2 white="true" className="opacity-90 mt-0 mb-0 vip_size">Ultra</H2>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 mt-5">Unlocks at <Image className="mr-5" src={OnboardingStarIcon} />5000 points</Paragraph>
             </Section>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 text-base">Everything in Platinum and...</Paragraph>
             </Section>
             <Section className="bottom-buttons displayflex relative overflow-x overflowhide">
              <DefaultButton className="vip_premium_btn mr-10 nowrap"><Image className="vertical-middle mr-10" src={OnboardingVideoIcon} />Virtual meet & greets</DefaultButton>
              <DefaultButton className="vip_premium_btn nowrap"><Image className="vertical-middle mr-10" src={OnboardingStarDateIcon} />Priority event access</DefaultButton>
             </Section>
            </Background>
            <Background className="a-list radius-4 pt-16 pb-16 pl-19 pr-19">
             <H2 white="true" className="opacity-90 mt-0 mb-0 vip_size">A-List</H2>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 mt-5">Unlocks at <Image className="mr-5" src={OnboardingStarIcon} />5000 points</Paragraph>
             </Section>
             <Section className="mb-20">
              <Paragraph white="true" className="opacity-50 text-base">Everything in Ultra and...</Paragraph>
             </Section> 
             <Section className="bottom-buttons displayflex relative overflow-x overflowhide">
              <DefaultButton className="vip_premium_btn mr-10 nowrap"><Image className="vertical-middle mr-10" src={OnboardingVideoIcon} />Virtual meet & greets</DefaultButton>
              <DefaultButton className="vip_premium_btn nowrap"><Image className="vertical-middle mr-10" src={OnboardingStarDateIcon} />Priority event access</DefaultButton>
             </Section>
            </Background>
            <Section className="max-590 mt-20">
             <DefaultButton className="mb-10 text-small-base fontSemiBold custom_bottom" onClick={() => goToCustomiseVault()}>Earn bonus points</DefaultButton>
             <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom" onClick={() => goToVault()} >Go to my Vault</DefaultButton>
            </Section>
           </Main>
          </SubContainer>
        </MainContainer>
        
    );
    }

export default Onboarding;