import React from "react";
import { MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import OrdercollectibleQuitarIcon from '../assets/images/collectible-quitar.svg';
import OnboardingStarIcon from '../assets/images/onboarding_star.svg';
import { H2, Paragraph, Span } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";

function ClaimCollectible() {
    return (
        <MainContainer>
            <Background />
            <SubContainer>
                <Main className="padding-25 relative">
                    <Section className="text-center relative mb-30">
                        <InnerHeader PageTitle={"Claim Collectible"} helpIcon={false} />
                    </Section>
                    <Section className="text-center mt-20 pb-150">
                        <Image className="m-auto displayblock" src={OrdercollectibleQuitarIcon} />
                        <H2 lightwhite="true" className="mtb-auto opacity-94 red_bottom">Bon Jovi’s Special</H2>
                        <Paragraph lightwhite="true" className="displayinlineblock mt-0 points_in_claim"><Image className="mr-5" src={OnboardingStarIcon} /><Span className="opacity-70">10 Points earned</Span></Paragraph>
                        <Paragraph lightwhite="true" className="opacity-70 humans">For attending the 'Dash Bash - New York' event at Jon Bon Jovi’s bar.</Paragraph>
                    </Section>
                    <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom">You’ve already claimed this reward</DefaultButton>
                </Main>
            </SubContainer>
        </MainContainer>
    );
}

export default ClaimCollectible;