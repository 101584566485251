import React from "react";
import { useNavigate } from "react-router-dom";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import OrderRedIcon from '../assets/images/order_red.svg';
import OnboardingStarIcon from '../assets/images/onboarding_star.svg';
import { H2, Paragraph, Span } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";

function Order() {  
  const navigate = useNavigate();
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25 relative">
            <Section className="text-center relative mb-30">
            <InnerHeader PageTitle={"Order a Red Wine"} helpIcon={false} />
            </Section>  
            <Section className="text-center mt-20 pb-150">
             <Image className="m-auto displayblock" src={OrderRedIcon} />
             <H2 lightwhite="true" className="mtb-auto opacity-94 red_bottom">Order a Red Wine</H2>
             <Paragraph lightwhite="true" className="displayinlineblock mt-0 points_in_claim"><Image className="mr-5" src={OnboardingStarIcon} /><Span className="opacity-70">10 Points claimed</Span></Paragraph>
             <Paragraph lightwhite="true" className="opacity-70 humans">Over the course of human history, wine has received its fair share of lauditory quotes, praise, almost even worship. It's arguably the most beloved beverage in the world.</Paragraph>
            </Section>
            <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom" onClick={() => navigate("/order-qrcode")}>Claim reward</DefaultButton>
           </Main>
          </SubContainer>
        </MainContainer>  
    );
    }

export default Order;