import React from "react";
import {  MainContainer, SubContainer, Main, Section, Image } from "../components/Styles";
import { Background } from "../components/Background";
import OrderDashCircleIcon from '../assets/images/orderdash_circle.svg';
import { H2, Paragraph, Span } from "../components/Text";
import { DefaultButton } from "../components/DefaultButton";
import InnerHeader from "../components/InnerHeader";

function OrderDashBash() {
    return (
        <MainContainer>
         <Background/>
          <SubContainer>
           <Main className="padding-25 relative">
            <Section className="text-center relative mb-30">
            <InnerHeader PageTitle={"Dash Bash - New York"} helpIcon={false} />
            </Section>  
            <Section className="text-center mt-20 pb-150">
             <Image className="m-auto displayblock" src={OrderDashCircleIcon} />
             <H2 lightwhite="true" className="mtb-auto opacity-94 red_bottom">Order any 3 items</H2>
             <Paragraph lightwhite="true" className="displayinlineblock mt-0 points_in_claim"><Span className="opacity-70">1/3 done</Span></Paragraph>
             <Paragraph lightwhite="true" className="opacity-70 humans">Over the course of human history, wine has received its fair share of lauditory quotes, praise, almost even worship. It's arguably the most beloved beverage in the world.</Paragraph>
            </Section>
            <DefaultButton className="text-small-base fontSemiBold skipin custom_bottom need_dash">You need to order 1 more</DefaultButton>
           </Main>
          </SubContainer>
        </MainContainer>  
    );
    }

export default OrderDashBash;