import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Background } from "../components/Background";
import VaultActive from "../assets/images/vault-active.svg";
import VaultInactive from "../assets/images/vault-inactive.svg";

// import ProfileActive from "../assets/images/profile-active.svg";
// import ProfileInActive from "../assets/images/profile-inactive.svg";

// import UpdateInactive from "../assets/images/updates-inactive.svg";
// import UpdateActive from '../assets/images/news.png';

import { Paragraph } from "../components/Text";

const Footers = styled.div`
  background:var(--bg-gray-color);
  width:100%;
  position:fixed;
  left:0;
  bottom:0;
  z-index:2;
  text-align:center;
  display:flex;
  justify-content:space-around;
  align-items:center;
  padding:10px 0 10px 0;

.active .text-top{
position:relative;
top:2px;}

`;


const Main = styled.div`
          /*  Desktop View  */
          @media (min-width:768px){
            width:640px;
            }
    
            /*  Tablet View  */
            @media (min-width:481px) and (max-width:767px){width:540px;}
    
            /*  Mobile View  */
            @media (min-width:320px) and (max-width:480px){width:100%;}   
`;

// const Text = styled.p`
//   font-size:16px;
//   color:rgba(255, 255, 255, 0.6);
//   display:flex;
//   align-items:center;
//   margin:0; 
// `;

// const Grid = styled.div`
//   flex-direction:column;
// `;


const IconContainer = styled.div`
  width:40px;
  height:40px;
  margin:0 auto;
`;


// const SpaceText = styled.p`
//     color:rgba(255, 255, 255, 0.3);
//     font-size:16px;
// `;

const Icon = styled.img`
  margin-bottom:0;
  `

const UL = styled.ul`
    width:100%;
   display:flex;
   padding-left:0;
   margin-top:0;
   margin-bottom:5px;
  

   & > li{
   width:100%;
   display:inline-block;
   padding:0;
   position:relative;

   &:before{
    content: '';
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 70%;
    display: inline-block;
    position: absolute;
    right: 0;
    top:5px;
    bottom: auto;
    margin: auto;
      }


   &:last-child:before{
   border-right:none;
   }   

   &.active{
   &:after{
    content: '';
    width: 55%;
    display: inline-block;
    height: 2px;
    position: absolute;
    background: #fff;
    bottom:-3px;
    left:0;
    right:0;
    margin:auto;
   }
    p{
      font-weight:bold;
      color:var(--white-color);
      }
   }
]

`;

function Footer() {
  const navigate = useNavigate();   
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <Footers>
      <Main>
        <UL>
          {/* <li className={currentPath === "/updates" ? "active" : ""} onClick={() => navigate("/updates")}>
            <Icon src={UpdateInactive} alt="Icon" className={currentPath === "/updates" ? "none" : ""}/>

            <IconContainer className={currentPath === "/updates" ? "block" : "none"}>
            <Background className="bottom_in_update_active">
              <Icon src={UpdateActive} alt="Icon" className="wd-20" />
            </Background>
            </IconContainer>
            <Paragraph gray="true" className="mt-5 mb-0 text-top">Updates</Paragraph>
          </li> */}
          
          <li className={currentPath === "/home" ? "active" : ""} onClick={() => navigate("/home")}>
            <Icon src={VaultInactive} alt="Icon" className={currentPath === "/home" ? "none" : ""}/>

            <IconContainer className={currentPath === "/home" ? "block" : "none"}>
            <Background className="bottom_in_update_active">
              <Icon src={VaultActive} alt="Icon" className="wd-20" />
            </Background>
            </IconContainer>
            <Paragraph gray="true" className="mt-5 mb-0 text-top">Vault</Paragraph>
          </li>

          {/* <li className={currentPath === "/profile" ? "active" : ""} onClick={() => navigate("/profile")}>
            <Icon src={ProfileActive} alt="Icon"/>
            <Paragraph gray="true" className="mt-5 mb-0">Profile</Paragraph>
          </li> */}
        </UL>
        </Main>
    </Footers>
  );
}

export default Footer;
